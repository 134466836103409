import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Solution.css";
import ReactDOM from 'react-dom';
import Box from '@material-ui/core/Box';
import Link from "@material-ui/core/Link";
import Button from '@mui/material/Button';
import HowIiWorksTab from '../components/HowIiWorksTab';
import HubspotForm from 'react-hubspot-form';
import Trustedbyindustries from '../components/Trustedbyindustries';
import AIPartnerSection from '../components/AIPartnerSection';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Retail(props) {
	var settings = {
		dots: false,
		infinite: true,
		vertical: false,
		autoplay:true,
		fade:true,
		arrows: false,
		speed: 4000,
 infinite: true,
 cssEase: 'ease-in-out',
 touchThreshold: 100
	  };
  return( 
    <>
 {/*Start banner section */}
  <div className="CommonBanner">
        <div className="CommonBannerImg">
		<Slider {...settings}>
			<div>
               <img src="../Images/Retail-banner-image.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
			</div>
			<div>
               <img src="../Images/Retail-banner-image-two.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
			</div>
			<div>
               <img src="../Images/Retail-banner-image-three.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
			</div>
			<div>
               <img src="../Images/Retail-banner-image-four.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
			</div>
		</Slider>
        </div>
        <div className="custom-container CommonBannerContent">
                <h1 className="h1-heading padding-bottom-16 font-black">Enabling <span className="text-green"> Digital Transformations </span> for Site and Branch Operations</h1>
                <p className="subtitle padding-bottom-30 font-regular">Dori AI provides enterprises with a suite of applications to address the needs of productivity, compliance, quality, and safety
</p>
                <Button variant="contained" className="rounded-white-btn" href="/contact"><span>Request Demo</span></Button>
        </div>
  </div>
{/*end banner section */}
{/* Visual Intelligence Platform for Enterprise Start */}
<div className="company-values Intelligence-Platform">
		<Grid container className="custom-container  section-padding-top">
          <Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
                <h2 className=" text-center h2-heading platform-max-width padding-bottom-36 font-black text-blue"><span class="text-green">Visual Intelligence Platform </span>for Enterprise</h2>
          </Grid>
          <Grid container className="grid-outer">
                <Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
                                          
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye" className="svg-inline--fa fa-eye svg-icon-color-blue margin-bottom-20 " role="img"  viewBox="0 0 576 512"><path fill="currentColor" d="M572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM432 256c0 79.45-64.47 144-143.9 144C208.6 400 144 335.5 144 256S208.5 112 288 112S432 176.5 432 256zM288 160C285.7 160 282.4 160.4 279.5 160.8C284.8 170 288 180.6 288 192c0 35.35-28.65 64-64 64C212.6 256 201.1 252.7 192.7 247.5C192.4 250.5 192 253.6 192 256c0 52.1 43 96 96 96s96-42.99 96-95.99S340.1 160 288 160z"></path></svg>  
                          <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Site Visibility</h4>
                          <p className="text-blue-faint subtitle">Monitor workforce movements and activities to improve productivity</p>
                </Grid>

                <Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="display" className="svg-inline--fa fa-user-group svg-icon-color-blue margin-bottom-20 svg-inline--fa fa-display"  role="img"  viewBox="0 0 576 512"><path fill="currentColor" d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 352H64V64h448V352z"></path></svg>
                          <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Process Monitoring</h4>
                          <p className="text-blue-faint subtitle">Ensure all SOPs and operations are compliant and measure efficiency</p>
                </Grid>
                <Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
                        <svg className="svg-icon-color-blue margin-bottom-20" viewBox="0 0 640 512"><path d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192z"/></svg>
						  <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Customer Experience</h4>
                          <p className="text-blue-faint subtitle">Monitor customer traffic and behaviors to improve customer service
</p>
                </Grid>
          </Grid>
		</Grid>
		</div>
{/* Visual Intelligence Platform for Enterprise End */}
{/* Start Key-Use-Cases*/}
<div className="custom-container section-padding-top Key-Use-Cases  section-padding-bottom">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >Key Use Cases</h2>
						    <p className="subtitle font-regular text-blue-faint">Dori AI enables enterprises with immense business value across a range of key use cases and many more.</p>
						</div>
					</Grid>
				<Grid  container spacing={3}>
				<Grid  item  item md={6} xl={6} sm={12}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1">
									<img height="100%" width="100%"className="desktop" src="../Images/Customer-Traffic-desktop.jpg" alt="Customer Traffic" decoding="async" loading="lazy"></img>
									<img height="100%" width="100%"className="mobile" src="../Images/Customer-Traffic-mobile.jpg" alt="Customer Traffic" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Customer Traffic</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Monitor queue lengths, wait times, and hot spots in order to better serve customers</p>
									    <div className="border-inner"></div>
										<Link href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={6} xl={6} sm={12}xs={12} className="vertical-center"  alignItems="center"  >
					    <div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								<Box  order={1}  className="trusted-box1">
									<img height="100%" width="100%"className="desktop" src="../Images/Workforce_Productivity_Monitoring-desk.jpg" alt="Workforce Productivity Monitoring" decoding="async" loading="lazy"></img>
									<img height="100%" width="100%"className="mobile" src="../Images/Workforce_Productivity_Monitoring_mob.jpg" alt="Workforce Productivity Monitoring" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Workforce Productivity Monitoring</h4>
										<p className="subtitle2 font-regular text-blue-faint">Monitor presence and time spent of workforce at each location or area to improve efficiency</p>
										 <div className="border-inner"></div>
										<Link href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
			   		 <Grid  item  item md={6} xl={6} sm={12}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								<Box  order={1} className="trusted-box1">
									<img height="100%" width="100%"className="desktop" src="../Images/Process-Compliance-Desktop.jpg" alt="Process Compliance" decoding="async" loading="lazy"></img>
									<img height="100%" width="100%"className="mobile" src="../Images/Process-Compliance-mobile.jpg" alt="Process Compliance" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Process Compliance</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Validate sequence and correctness of operations and flag alerts for any violations</p>
										<div className="border-inner"></div>
										<Link href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={6} xl={6} sm={12}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1">
									<img height="100%" width="100%"className="desktop" src="../Images/Space-Monitoring.jpg" alt="Space Utilization" decoding="async" loading="lazy"></img>
									<img height="100%" width="100%"className="mobile" src="../Images/Space-Monitoring-mob.jpg" alt="Space Utilization" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Space Utilization</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Monitor store layouts and product placement to optimize space and resources </p>
										<div className="border-inner"></div>
										<Link href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					
				</Grid>
				</Grid>
			</div>
{/* End Key-Use-Cases*/}
{/* Our Key Results Start */}
<div className="Key_Results" style={{ backgroundImage: 'url("../Images/gray-dotted-bg.jpg") '}}>
			<Grid container className="custom-container padding-bottom-96 padding-top-96">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
							<h2 className=" text-center h2-heading company-max-width padding-bottom-30 text-c font-black text-blue">Delivering Key Results for<br></br> <span class="text-green">Site and Branch Operators</span></h2>
				</Grid>
				<Grid  container spacing={3}>
				 <Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg  className="gear svg-icon-color-blue "viewBox="0 0 512 512"><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64C238.3 64 224 78.33 224 96C224 113.7 238.3 128 256 128C273.7 128 288 113.7 288 96C288 78.33 273.7 64 256 64zM256 416C291.3 416 320 387.3 320 352C320 348.3 319.7 344.7 319.1 341.2L436.6 268.4C447.9 261.4 451.4 246.6 444.4 235.4C437.4 224.1 422.6 220.6 411.4 227.6L293.8 300.4C283.2 292.6 270.1 288 256 288C220.7 288 192 316.7 192 352C192 387.3 220.7 416 256 416L256 416zM144 112C126.3 112 112 126.3 112 144C112 161.7 126.3 176 144 176C161.7 176 176 161.7 176 144C176 126.3 161.7 112 144 112zM96 288C113.7 288 128 273.7 128 256C128 238.3 113.7 224 96 224C78.33 224 64 238.3 64 256C64 273.7 78.33 288 96 288zM368 112C350.3 112 336 126.3 336 144C336 161.7 350.3 176 368 176C385.7 176 400 161.7 400 144C400 126.3 385.7 112 368 112z"/></svg>
								
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Increased Operational Efficiency</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								
								<svg className="gear svg-icon-color-blue smile"  viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM164.1 325.5C158.3 318.8 148.2 318.1 141.5 323.9C134.8 329.7 134.1 339.8 139.9 346.5C162.1 372.1 200.9 400 255.1 400C311.1 400 349.8 372.1 372.1 346.5C377.9 339.8 377.2 329.7 370.5 323.9C363.8 318.1 353.7 318.8 347.9 325.5C329.9 346.2 299.4 368 255.1 368C212.6 368 182 346.2 164.1 325.5H164.1zM176.4 176C158.7 176 144.4 190.3 144.4 208C144.4 225.7 158.7 240 176.4 240C194 240 208.4 225.7 208.4 208C208.4 190.3 194 176 176.4 176zM336.4 240C354 240 368.4 225.7 368.4 208C368.4 190.3 354 176 336.4 176C318.7 176 304.4 190.3 304.4 208C304.4 225.7 318.7 240 336.4 240z"/></svg ><p className="text-green font-black  ten">10/10</p>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Higher Customers NPS Scores </p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg className="gear svg-icon-color-blue" viewBox="0 0 512 512"><path d="M320 96H192L144.6 24.88C137.5 14.24 145.1 0 157.9 0H354.1C366.9 0 374.5 14.24 367.4 24.88L320 96zM192 128H320C323.8 130.5 328.1 133.3 332.1 136.4C389.7 172.7 512 250.9 512 416C512 469 469 512 416 512H96C42.98 512 0 469 0 416C0 250.9 122.3 172.7 179 136.4C183.9 133.3 188.2 130.5 192 128V128zM276.1 224C276.1 212.9 267.1 203.9 255.1 203.9C244.9 203.9 235.9 212.9 235.9 224V230C230.3 231.2 224.1 232.9 220 235.1C205.1 241.9 192.1 254.5 188.9 272.8C187.1 283 188.1 292.9 192.3 301.8C196.5 310.6 203 316.8 209.6 321.3C221.2 329.2 236.5 333.8 248.2 337.3L250.4 337.9C264.4 342.2 273.8 345.3 279.7 349.6C282.2 351.4 283.1 352.8 283.4 353.7C283.8 354.5 284.4 356.3 283.7 360.3C283.1 363.8 281.2 366.8 275.7 369.1C269.6 371.7 259.7 373 246.9 371C240.9 370 230.2 366.4 220.7 363.2C218.5 362.4 216.3 361.7 214.3 361C203.8 357.5 192.5 363.2 189 373.7C185.5 384.2 191.2 395.5 201.7 398.1C202.9 399.4 204.4 399.9 206.1 400.5C213.1 403.2 226.4 407.4 235.9 409.6V416C235.9 427.1 244.9 436.1 255.1 436.1C267.1 436.1 276.1 427.1 276.1 416V410.5C281.4 409.5 286.6 407.1 291.4 405.9C307.2 399.2 319.8 386.2 323.1 367.2C324.9 356.8 324.1 346.8 320.1 337.7C316.2 328.7 309.9 322.1 303.2 317.3C291.1 308.4 274.9 303.6 262.8 299.9L261.1 299.7C247.8 295.4 238.2 292.4 232.1 288.2C229.5 286.4 228.7 285.2 228.5 284.7C228.3 284.3 227.7 283.1 228.3 279.7C228.7 277.7 230.2 274.4 236.5 271.6C242.1 268.7 252.9 267.1 265.1 268.1C269.5 269.7 283 272.3 286.9 273.3C297.5 276.2 308.5 269.8 311.3 259.1C314.2 248.5 307.8 237.5 297.1 234.7C292.7 233.5 282.7 231.5 276.1 230.3L276.1 224z"/></svg>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Improved Sales Revenue</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg className="gear svg-icon-color-blue" viewBox="0 0 640 512"><path d="M304 95.1c26.5 0 48-21.5 48-47.1S330.5 0 304 0S256 21.5 256 47.1S277.5 95.1 304 95.1zM560 0h-96C437.5 0 416 21.5 416 47.1v175.1h-44.22l-26.97-53.95c-11.68-23.36-32.32-40.93-57.25-48.7L219.8 100.2c-31.9-9.984-66.69-2.625-91.84 19.39L90.94 151.9C77.63 163.5 76.29 183.8 87.9 197.1c11.65 13.28 31.87 14.62 45.18 3.008l36.99-32.38c8.352-7.293 19.87-9.758 30.46-6.525l-30.95 92.8c-9.344 28.03 1.729 58.85 26.82 74.49l86.21 53.88l-25.41 88.83c-4.832 16.99 4.992 34.72 21.98 39.58c16.99 4.832 34.72-4.992 39.58-21.98l28.67-100.4c5.887-20.57-2.561-42.56-20.71-53.88L270 299l28.99-77.37L318.9 261.5c8.129 16.26 24.77 26.53 42.95 26.53H416v175.1c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V47.1C608 21.5 586.5 0 560 0zM145.5 319.1l-14.4 32.9H64c-17.66 0-32 14.34-32 31.1c0 17.66 14.34 32 32 32h77.54c19.07 0 36.32-11.3 43.97-28.77l9.568-21.86l-15.62-9.76C164.7 346.4 153.2 333.7 145.5 319.1z"/></svg>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Reduced Employee Turnover</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box ">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg className=" gear svg-icon-color-blue " viewBox="0 0 640 512"><path d="M274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM632.3 134.4c-9.703-9-24.91-8.453-33.92 1.266l-87.05 93.75l-38.39-38.39c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94l56 56C499.5 285.5 505.6 288 512 288h.4375c6.531-.125 12.72-2.891 17.16-7.672l104-112C642.6 158.6 642 143.4 632.3 134.4z"/></svg>
									</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Increased Resource Utilization</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg  className=" gear svg-icon-color-blue "  viewBox="0 0 576 512"><path d="M466.7 352L320 205.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6L9.372 150.6C-3.124 138.1-3.124 117.9 9.372 105.4C21.87 92.88 42.13 92.88 54.63 105.4L191.1 242.7L297.4 137.4C309.9 124.9 330.1 124.9 342.6 137.4L512 306.7V223.1C512 206.3 526.3 191.1 544 191.1C561.7 191.1 576 206.3 576 223.1V384C576 401.7 561.7 416 544 416H384C366.3 416 352 401.7 352 384C352 366.3 366.3 352 384 352L466.7 352z"/></svg>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Fewer Accident and Hazards</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					
				</Grid>
			</Grid>
				
		</div>
{/* Our Company Values Section End */}
{/* Start  Simplified section*/}
<div className="custom-container  Simplified section-padding-top  trusted-by-section">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className=" vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >We have <span style={{color:"#45BEA0"}}> Simplified </span>the Tech</h2>
						    <p className="subtitle font-regular text-blue-faint">Dori AI provides a suite of template AI computer vision applications across a broad range of manufacturing needs </p>
						</div>
					</Grid>
				<Grid  container spacing={3}>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
					    <div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
									<img height="100%" width="100%"className="" src="../Images/Real-Time-Feedback-Retail.svg" alt="Real Time Feedback" decoding="async" loading="lazy"></img>
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Real-Time Feedback</h4>
										<p className="subtitle2 font-regular text-blue-faint">24/7 live monitoring and alerts for branch managers and on-site personnel</p>
										
									</div>
								
							</Box>
						</div>
					</Grid>
			   		 <Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								
									<img height="100%" width="100%"className="" src="../Images/AI-Driven-Kaizen.svg" alt="AI Driven Kaizen" decoding="async" loading="lazy"></img>
																	
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >AI-Driven Decisions</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Capture performance indicators and improve key customer and employee metrics</p>
										
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%"className="" src="../Images/Instant-Video-Replay.svg" alt="Instant Video Replay" decoding="async" loading="lazy"></img>
																
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Instant Video Replay</h4>
										<p className="subtitle2 font-regular text-blue-faint ">For on-the-spot troubleshooting and new or existing employee training</p>
										
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%"className="" src="../Images/Intelligent-Visual-Search.svg" alt="Intelligent Visual Search" decoding="async" loading="lazy"></img>
									
								
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Intelligent Visual Search</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Search visual metadata by area, branch, zone, or shift for faster root cause analysis</p>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
				</Grid>
				</Grid>
			</div>
{/* End trusted by section*/}
{/* how it works section start*/}
{/*<div className="custom-container  ">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-60">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >How It Works</h2>
						    <p className="subtitle font-regular text-blue-faint">We have standardized the approach to build robust computer vision applications. With our platform, you can get to production within weeks (not months). Our no-code/low-code approach enables subject matter experts to quickly build and configure one of our CV application templates to service any use case.</p>
						</div>
					</Grid>
                </Grid>
               
				<HowIiWorksTab/>
                
                </div>*/}
{/* how it works section End*/}
{/* Start trusted by section*/}
  						<Trustedbyindustries/>
{/* End trusted by section*/}
{/* start case study section*/}
			{/*<div className="custom-container section-padding-top case-study" id="casestudy">
				<Grid container className="text-center">
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >Case Studies</h2>
						    <p className="subtitle font-regular text-blue-faint">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
						</div>
					</Grid>
				</Grid>
				<Grid container className="case-study-inner" spacing={3}>
					<Grid  item  item md={6} xl={6} sm={12} xs={12} className="vertical-center"  alignItems="center"  >
							
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								<Box  order={1}  className="box1">
								<img height="100%" width="100%"className="desktop" src="/Images/Intelligent-visual-inspection-of-assembly-SOPs.jpg" alt="Intelligent visual inspection of assembly SOPs"></img>
									<img height="100%" width="100%"className="mobile" src="/Images/manager-engineer-touch-screen-control-automation-robot-mobile.png" alt="Intelligent visual inspection of assembly SOPs"></img>
								</Box>
								<Box  order={2}  className="box2">
									<div className="inner-text">		
										<h4 className="subtitle font-black padding-bottom-16 text-blue" >Intelligent visual inspection of assembly SOPs</h4>
										<p className="subtitle2 font-regular text-blue-faint">Capturing the actions of each assembly operation from an engine transmission assembly station and automatically flagged.</p>
										<div >
											<ul >
											<li>
												<p className="subtitle font-black padding-bottom-9 text-blue ">50%</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint ">Reduction in maintenance costs  </p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1M+</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Project completed successfully</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">17</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Years of experience</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1000</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">We work with the trusted clients</p>
											</li>
											</ul>
										</div>
										<div className="border"></div>
										<a href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Contact Us</a>
									</div>
								</Box>
							</Box>
						</div>
						
					</Grid>
					<Grid  item  item md={6} xl={6} sm={12} xs={12} className="vertical-center"  alignItems="center"  >
					<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1}  className="box1">
									<img height="100%" width="100%"className="desktop" src="/Images/Intelligent-visual-inspection-of-assembly-SOPs.jpg" alt="Intelligent visual inspection of assembly SOPs"></img>
									<img height="100%" width="100%"className="mobile" src="/Images/manager-engineer-touch-screen-control-automation-robot-mobile.png" alt="Intelligent visual inspection of assembly SOPs"></img>
								</Box>
								<Box  order={2}  className="box2">
									<div className="inner-text">		
										<h4 className="subtitle font-black padding-bottom-16 text-blue" >Intelligent visual inspection of assembly SOPs</h4>
										<p className="subtitle2 font-regular text-blue-faint">Capturing the actions of each assembly operation from an engine transmission assembly station and automatically flagged.</p>
										<div >
											<ul >
											<li>
												<p className="subtitle font-black padding-bottom-9 text-blue ">50%</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint ">Reduction in maintenance costs  </p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1M+</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Project completed successfully</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">17</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Years of experience</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1000</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">We work with the trusted clients</p>
											</li>
											</ul>
										</div>
										<div className="border"></div>
										
										<a href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Contact Us</a>
										
									{/*</div>
								</Box>
							</Box>
						</div>
					</Grid>
					
				</Grid>
				<Grid  item  item md={12} xl={12} xs={12} className="text-center case-study-button" alignItems="center">
					<Button variant="contained" className="footer-btn "><span>Explore more Case Studies</span></Button>
					</Grid>
									</div>*/}
{/* end case study section*/}
{/* Our Trusted & Technology Partnerships Section Start */}
{/*<Grid container className="custom-container technology-partnerships section-container">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
					<h2 className="h2-heading padding-bottom-16 text-c font-black text-blue text-center">Our <span class="text-green"> Trusted & Technology </span>Partnerships</h2>
					<p className="subtitle text-blue center-subheading text-center padding-bottom-36 text-blue-faint font-regular" alignItems="center">Dori Ai is helping global brands to significantly increase human productivity & improve process compliances.</p>
				</Grid>
				<Grid container className="grid-outer">
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/AWS.svg" alt="AWS" width="54px" height="32px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Microsoft Azure.svg" alt=" Azure" width="61px" height="33px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Google-cloud-platform-v2.svg" alt="Google-cloud-platform" width="37px" height="32px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" width="104px" height="20px" item md={4} xl={4} xs={12}>
						<img src="/Images/NVIDIA.svg" alt="NVIDIA" ></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" width="54px" height="36px"  item md={4} xl={4} xs={12}>
						<img src="/Images/intel.svg" alt="intel"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Plug-and-Play.svg" alt="Plug-and-Play" width="96px" height="18px"></img>
					</Grid>
			    </Grid>
	</Grid>*/}
			{/* Our Trusted & Technology Partnerships Section End */}
			{/* Start  resources section*/}
{/*<div className="custom-container  Simplified section-padding-bottom trusted-by-section">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >Resources</h2>
						    <p className="subtitle font-regular text-blue-faint">Read more about how we are enabling our partners and customers with a seamless platform to build and deploy computer vision applications with ease. </p>
						</div>
					</Grid>
				<Grid  container spacing={5}>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
					    <div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
									<img height="100%" width="100%"className="" src="/Images/blog3.jpg" alt="Real Time Feedback"></img>
									<div className="inner-text">		
									   <p className="subtitle2 font-black margin-bottom-10 text-blue">Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>
									   <p className="subtitle2 font-regular text-blue-faint">5 min. Read | 12 July 2021</p>
									   <div className="border-inner"></div>
										<Link to="#" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								
							</Box>
						</div>
					</Grid>
			   		 <Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								
									<img height="100%" width="100%"className="" src="/Images/blog1.jpg" alt="AI Driven Kaizen"></img>
																	
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	
										<p className="subtitle2 font-black  text-blue margin-bottom-10 ">Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>
										<p className="subtitle2 font-regular text-blue-faint ">5 min. Read | 12 July 2021</p>
										<div className="border-inner"></div>
										<Link to="#" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%"className="" src="/Images/blog1.jpg" alt="Instant Video Replay"></img>
																
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
											<p className="subtitle2 font-black  text-blue margin-bottom-10">Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>
											<p className="subtitle2 font-regular text-blue-faint ">5 min. Read | 12 July 2021</p>
										   <div className="border-inner"></div>
										   <Link to="#" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					
				</Grid>
				</Grid>
</div>*/}
{/* End R section*/}
			{/* We are Nerd Section Start */}
			<AIPartnerSection/>
{/* We are Nerd Section end */}
{/*form start*/}
<div id="popup1" className="overlay  contact-us-section section-padding-bottom">
				                            <Grid container className="popup" >
			        
												<Grid  item  item md={5} xl={5} xs={12} className="contact-inner"  alignItems="center"  >
												    <a class="close" href="#casestudy">&times;</a>
													<h2 className="h2-heading text-white font-black padding-bottom-20">Contact Us</h2>
													{/*<p className="subtitle font-regular text-white padding-bottom-36">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>*/}
													
													<HubspotForm
															region= "na1"
															portalId="6819704"
															formId ="bfac7d9b-11f0-407d-bf0b-f863128a3ceb"
			 
               										 />

												</Grid>
											</Grid>
										</div>
										{/*End form*/}

    </>
  );
}