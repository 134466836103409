import Slider from "react-slick";
import React from "react";
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
class ComputerVisionSlider extends React.Component  {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      vertical: false,
      autoplay:true,
       fade: false,
       arrows: false,
       rtl: true,
      speed:2000
    };
    return (
      <div className="container">
        <Slider {...settings}>
        <div>
      <img alt="dori" src="/Imgs/Manufacturing.png" decoding="async" loading="lazy" width="524" height="517"/>
      
    </div>
    <div>
      <img alt="dori"  src="/Imgs/Healthcare.png" decoding="async" loading="lazy" width="547" height="517" />
      
    </div>
    <div>
      <img alt="dori"  src="/Imgs/Retail.png" decoding="async" loading="lazy" width="490" height="461"  />
      
    </div>
        </Slider>
      </div>
    );
  }
}
export default ComputerVisionSlider;

