import "./App.css";
import Layout from "./components/Layout";
import { useState } from "react";

function App() {
	const [alignment, setAlignment] = useState("right");
	return (
		<div className="App">
			<Layout></Layout>
		</div>
	);
}

export default App;
