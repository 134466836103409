import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Contact.css";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
//import ContactForm from '../components/ContactForm';
import HubspotForm from 'react-hubspot-form';

export default function Contact(props){
    return( 
        <>
    {/* Banner Section Start*/ }
    <div className="CommonBanner">
        <div className="CommonBannerImg">
            <img src="/Images/Contact-Us-banner.jpg" alt="Contact uu banner" width="100%" height="100%"></img>
        </div>
        <div className="custom-container CommonBannerContent">
            <h1 className="h1-heading padding-bottom-16 font-black">We'd Love to <span className="text-green"> Connect</span></h1>
            <p className="subtitle padding-bottom-30 font-regular">We are on a mission to digitize the physical world through computer vision and bring real-time operational insights to enterprises.</p>
        </div>
    </div>
    {/* Banner Section End */}
	{/*contact us section Start*/}
	<div className="Contact-us-section margin-top-3">
		<Grid container className=" ">
		    <Grid container direction="row" className=" contact-from section-padding-top " item md={7} xl={7} sm={12} xs={12}>
				<div className="form">
				<h2 className="h2-heading padding-bottom-30  font-black text-blue">Contact Us</h2>
				{/*<ContactForm/>*/}
				<HubspotForm
                  portalId='6819704'
                  formId='58e358d7-0d60-4d12-a83b-6569596ff20c'
                  onSubmit={() => console.log('Submit!')}
                  onReady={(form) => console.log('Form ready!')}
                  loading={<div>Loading...</div>}
                />

			     </div>
			</Grid>
			<Grid container style={{ backgroundImage: 'url("../Images/bg.png") '}} direction="row" alignItems="center" className=" section-padding-top section-padding-bottom  about-dori" item md={5} xl={5} sm={12} xs={12}>
			<div className="form">
				<h2 className="h2-heading padding-bottom-16 text-c font-black text-white">About Dori</h2>
				<p  className="subtitle padding-bottom-30 text-c font-regular text-white">Dori AI is a full stack end-to-end computer vision and deep learning video analytics platform to significantly increase human productivity and improve process compliance.</p>
			    <Link to="/about" className="subtitle2 font-bold case-study-anchor link-animation text-white">Learn More</Link>
				<div className="find-us padding-top-bottom-60">
						<h4 className="h3-heading padding-bottom-30 text-c font-black text-white">Find us at</h4>
						<div className="map padding-bottom-20">
						{/*<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" className=" margin-bottom-10 svg-inline--fa fa-location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"></path></svg>*/}
						<img src="/Images/united-states-of-america.png" width="36px" height="23px"></img>
						
						<p className="h4-heading  text-c font-bold text-white">US Offices</p>
						</div>
						<ul>
							<li className="h4-heading padding-bottom-30 text-c font-regular text-white">200 East 32nd Street <br></br>Suite 21C <br></br> New York NY 10016</li>
							<div className="border"></div>
							<li className="h4-heading padding-bottom-30 text-c font-regular text-white">440 N Wolfe Rd <br></br>Sunnyvale CA 94085</li>
						</ul>
				</div>
				<div className="find-us ">
				<div className="map padding-bottom-20">
						{/*<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" className=" margin-bottom-10 svg-inline--fa fa-location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"></path></svg>*/}
						<img src="/Images/india.png" width="36px" height="36px"></img>
						<h4 className="h4-heading  text-c font-bold text-white">India Development Center</h4>
					</div>
						<p className="h4-heading  text-c font-regular text-white para">BHIVE Workspace MG Road Mahalakshmi Chambers 29 MG Road Bengaluru Karnataka 560001</p>
				</div>
			</div>
			</Grid>
		</Grid>
	</div>
	{/*contact us section Start*/}
    {/* What Happens Next? Start*/}
			<Grid container className="custom-container section-padding-top section-padding-bottom how-we-are What-Happens-Next" >
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} sm={12} xs={12}>
							<h2 className="h2-heading padding-bottom-36 text-c font-black text-blue">What Happens <span class="text-green">Next?</span></h2>
				</Grid>
				<Grid container className="grid-outer">
				<Grid  variant="outlined" item md={4} xl={4} xs={12} className="grid-inner">
                                   <div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>01</span></div>  
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Discuss your objectives</h4>
								   <p className="text-blue-faint subtitle font-regular">We are here to understand the challenges and problems facing your enterprise and discuss how AI and computer vision can help.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} sm={12}  xs={12} className="grid-inner">
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>02</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Create a proposal for you</h4>
								   <p className="text-blue-faint subtitle font-regular">Once we understand your use cases, we will put together an action plan that enables you to quickly start gaining real-time operational insights.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} sm={12}  xs={12} className="grid-inner">
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>03</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Start working together</h4>
								   <p className="text-blue-faint subtitle font-regular">Our relationship does not end with the first deployment - AI is an evolutionary process. We are here to grow with you as your business expands.</p>
				</Grid>
			</Grid>
		    </Grid>
			{/* What Happens Next? End*/}
			{/* Our Trusted & Technology Partnerships Section Start */}
{/*<Grid container className="custom-container technology-partnerships section-container">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
					<h2 className="h2-heading padding-bottom-16 text-c font-black text-blue text-center">Our <span class="text-green"> Trusted & Technology </span>Partnerships</h2>
					<p className="subtitle text-blue center-subheading text-center padding-bottom-36 text-blue-faint font-regular" alignItems="center">Dori Ai is helping global brands to significantly increase human productivity & improve process compliances.</p>
				</Grid>
				<Grid container className="grid-outer">
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/AWS.svg" alt="AWS" width="54px" height="32px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Microsoft Azure.svg" alt=" Azure" width="61px" height="33px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Google-cloud-platform-v2.svg" alt="Google-cloud-platform" width="37px" height="32px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" width="104px" height="20px" item md={4} xl={4} xs={12}>
						<img src="/Images/NVIDIA.svg" alt="NVIDIA" ></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" width="54px" height="36px"  item md={4} xl={4} xs={12}>
						<img src="/Images/intel.svg" alt="intel"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Plug-and-Play.svg" alt="Plug-and-Play" width="96px" height="18px"></img>
					</Grid>
			    </Grid>
	</Grid>*/}
			{/* Our Trusted & Technology Partnerships Section End */}
    </>
  );
}

