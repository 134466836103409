import React, { Component } from "react";

import Navbar from "./navbar/Navbar";
import Footer from "./Footer";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Blog from "../pages/Blog";
import About from "../pages/About";
import Solutions from "../pages/Solutions";
import Contact from "../pages/Contact";
import Retail from "../pages/Retail";
import Careers from "../pages/Careers";
import DataScientist from "../pages/DataScientist";
import PythonDeveloper from "../pages/PythonDeveloper";
import ProductManager from "../pages/ProductManager";
import FrontEndDeveloper from "../pages/FrontEndDeveloper";
import UIDeveloper from "../pages/UIDeveloper";
import TechnicalContentMarketingSpecialist from "../pages/TechnicalContentMarketingSpecialist";
import DevOpsEngineer from "../pages/DevOpsEngineer";
import SeniorSoftwarEengineer from "../pages/SeniorSoftwarEengineer";
import SrFullStackDeveloperSystemIntegrationDevOps from "../pages/SrFullStackDeveloperSystemIntegrationDevOps";
import Manufacturing from "../pages/Manufacturing";
import Logistics from "../pages/Logistics";
import SecurityAndSafety from "../pages/SecurityAndSafety";
import Platform from "../pages/Platform";
import PageNotFound from "../pages/404-error";
import './global.css';
import Thankyou from "../pages/Thankyou";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Post from "../pages/Post";
import TermsandConditions from "../pages/TermsandConditions";
import GlobalStyle from "../styles/Global";



class Layout extends  Component {
	
 state = {
		navbarOpen: false
	  };
	
	  handleNavbar = () => {
		this.setState({ navbarOpen: !this.state.navbarOpen });
	  };
	  render() {
		return (
		  <>
			<Navbar
			  navbarState={this.state.navbarOpen}
			  handleNavbar={this.handleNavbar}
			/>
			<GlobalStyle />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="about" element={<About />} />
				<Route path="solutions" element={<Solutions />} />
				<Route path="contact" element={<Contact />} />
				<Route path="solutions/siteoperations" element={<Retail/>} />
				<Route path="Careers" element={<Careers/>} />
				{/*<Route path="blog" element={<Blog />} />*/}
				<Route path="solutions/manufacturing" element={<Manufacturing/>} />
				<Route path="solutions/logistics" element={<Logistics/>} />
				<Route path="Platform" element={<Platform/>} />
				<Route path="jobs/apply/datascientist" element={<DataScientist/>} />
				<Route path="jobs/apply/pythondeveloper" element={<PythonDeveloper/>} />
				<Route path="jobs/apply/productmanager" element={<ProductManager/>} />
				<Route path="jobs/apply/frontenddeveloper" element={<FrontEndDeveloper/>} />
				<Route path="jobs/apply/uideveloper" element={<UIDeveloper/>} />
				<Route path="jobs/apply/technicalcontentmarketingspecialist" element={<TechnicalContentMarketingSpecialist/>} />
				<Route path="jobs/apply/devOpsengineer" element={<DevOpsEngineer/>} />
				<Route path="jobs/apply/seniorsoftwareengineer" element={<SeniorSoftwarEengineer/>} />
				<Route path="jobs/apply/srfullstackdevelopersystemintegrationdevOps" element={<SrFullStackDeveloperSystemIntegrationDevOps/>} />
				<Route path="solutions/securitysafety" element={<SecurityAndSafety/>} />
				{/*<Route path="/blog/:slug" element={<Post/>} />*/}
				<Route path="thankyou" element={<Thankyou/>} />
				<Route path="privacypolicy" element={<PrivacyPolicy/>} />
				<Route path="termsandconditions" element={<TermsandConditions/>} />
				<Route path="*" element={<PageNotFound/>} />
			</Routes>
			<Footer></Footer>
		  </>
		);
	  }
}
export default Layout;