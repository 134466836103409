import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Job.css";

import Link from "@material-ui/core/Link";
import HubspotForm from 'react-hubspot-form';
export default function DataScientist(props) {
    
	return (
        <div className="custom-container job-page">
            <Link href="/careers" className="subtitle2 link">Back</Link>
        <div className=" padding-top-bottom-60 ">
            <Grid container spaciing={2}>
					<Grid  item  md={7} sm={12} xs={12} className="vertical-center"  >
						<div className="inner-div ">		
							<h2 className="h2-heading font-black margin-bottom-10 text-blue" >Data Scientist</h2>
						 	<p className="subtitle font-regular text-blue">2 - 4 yrs | Bengaluru North, Karnataka, India</p>
                            <div className="job-border"></div>
                            <div className="job-desciption dec">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Job Description:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">We are looking for a Data Scientist to analyze large amounts of raw information to find patterns that will help improve our company. We will rely on you to build data products to extract valuable business insights.</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">In this role, you should be highly analytical with a knack for analysis, math and statistics. Critical thinking and problem-solving skills are essential for interpreting data. We also want to see a passion for machine-learning and research.</li>
                                      <li  className="subtitle font-regular text-blue-faint ">Your goal will be to help our company analyze trends to make better decisions.</li>
                                  </ul>    
                            </div>   
                            <div className="job-desciption Requirements">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Requirements:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">2 to 4 years of relevant industry experience</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Experience in Linear algebra, statistics & Probability skills, such as distributions, Deep Learning, Machine Learning</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Strong mathematical and statistics background is a must</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience in machine learning frameworks such as Tensorflow, Caffe, PyTorch, or MxNet</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Strong industry experience in using design patterns, algorithms and data structures</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Industry experience in using feature engineering, model performance tuning, and optimizing machine learning models</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Hands on development experience in Python and packages such as NumPy, Sci-Kit Learn and Matplotlib</li>
                                      <li className="subtitle font-regular text-blue-faint ">Experience in model building, hyper Skills:- Data Science, Python, Computer Vision and Deep Learning</li>
                                  </ul>    
                            </div>    
                            <div className="job-desciption Details">
                                  <ul>
                                  <li className="subtitle font-regular padding-bottom-16 text-blue" ><b>Seniority level:</b>
                                   <p> Mid-Senior level</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue">  <b>Employment type:</b>
                                    <p>Full-time</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"> <b>Job function:</b>
                                    <p>Engineering and Information Technology</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"><b>Industries:</b>
                                    <p>IT Services and IT Consulting, Software Development, and Internet Publishing</p></li>
                                </ul>    
                            </div>        
                        </div>
					</Grid>
                    {/*<Grid  item  md={5} sm={12} xs={12} className=""  >
						<div className=" ">	
                        <h2 className="h2-heading font-black padding-bottom-36 text-blue" >Application Form</h2>	
						  <ApplicationForm/>  
                          
                           <HubspotForm
                             region= 'na1'
                  portalId='6819704'
                  formId='1af92a5c-b450-483a-b450-f7edb713e64b'
                 
                />

        
                        </div>
					</Grid>*/}  
            </Grid>

        </div>
    </div>
    );

}