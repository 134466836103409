import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Homepage.css";
import ReactDOM from 'react-dom';
import Box from '@material-ui/core/Box';
import { Link } from "react-router-dom";
import ComputerVisionSlider from '../components/Slider/ComputerVisionSlider';
import Button from '@mui/material/Button';
import Trustedbyindustries from '../components/Trustedbyindustries';
import AIPartnerSection from '../components/AIPartnerSection';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Home(props) {
  var settings = {
    dots: false,
    infinite: true,
    vertical: false,
    autoplay:true,
    fade:true,
    arrows: false,
    speed: 3000,
    infinite: true,
    cssEase: 'ease-in-out',
    touchThreshold: 100
  };
	return (
		<section>
      {/*Start banner section */}
      <div className="CommonBanner">
        <div className="CommonBannerImg">           
          <Slider {...settings}>
            <div>
              <img src="..\Images/Manufacturing-banner-image.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
            </div>
            <div>
              <img src="../Images/Logistics-banner.jpg" alt="Logistics banner image" width="100%" height="100%"></img>
            </div>
            <div>
              <img src="..\Images/Manufacturing-banner-two.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>      
            </div>
            <div>
              <img src="..\Images/Manufacturing-banner-three.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>      
            </div>
            <div>
              <img src="..\Images/Manufacturing-banner-five.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>      
            </div>
            <div>
              <img src="../Images/Logistics-banner-two.jpg" alt="Logistics banner image" width="100%" height="100%"></img>
            </div>
            <div>
              <img src="../Images/Logistics-banner-three.jpg" alt="Logistics banner image" width="100%" height="100%"></img>
            </div>
          </Slider>
        </div>
        <div className="custom-container CommonBannerContent">
          <h1 className="h1-heading padding-bottom-16 font-black">AI Video <span className="text-green">Intelligence </span> Platform</h1>
          <p className="subtitle padding-bottom-30 font-regular">Empowering businesses with real-time visibility and AI-powered operational insights</p>
          <Button variant="contained" className="rounded-white-btn" href="/contact"><span>Request Demo</span></Button>
        </div>
      </div>
     {/*end banner section */}
     {/* start leading digital transformation  */}
			<div className=" custom-container  section-padding-top section-padding-bottom margin-top-3 ComputerVision">
				<Grid container>
					<Grid  container item direction="row"  md={6} className="home-about  " >
						<div className="inner-div extra ">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >Digitize enterprise operations into visual insights through<br></br> <span style={{color:"#45BEA0"}}>Computer Vision </span> </h2>
						 	<p className="subtitle font-regular padding-bottom-16 text-blue-faint">Dori AI enables enterprises with a no-code platform to transform images and videos into actionable insights. Our technology distills visual data into people, processes, and assets and provides businesses with real-time metrics and analytics.</p>            
                             <p className="subtitle font-bold margin-bottom-10 text-blue">Real-time AI Video Analytics</p>	
							 <p className="subtitle font-regular margin-bottom-16 text-blue-faint">Tailor the system for any enterprise use case; instantly detect any person, process, or asset.</p>						
							 <p className="subtitle font-bold margin-bottom-10 text-blue">Robust Suite of Computer Vision Applications</p>	
							 <p className="subtitle font-regular margin-bottom-16 text-blue-faint">Choose from one of our pre-configured application templates designed with intuitive dashboards interfaces and instant video replay of any event.</p>
							 <p className="subtitle font-bold margin-bottom-10 text-blue">Configure and Deploy on Edge or Cloud</p>	
							 <p className="subtitle font-regular padding-bottom-30 text-blue-faint">Easily onboard any camera or visual data source; deploy and manage thousands of endpoints through centralized dashboards and APIs.
</p>						<Button variant="contained" className="footer-btn " href="/platform"><span>Learn More</span></Button>
						</div>
					</Grid>
				 	
					<Grid  container item direction="row"  md={6} className="vertical-center color" >{/*<img src="/Images/Leading Digital Transformation of the Physical World through Computer Vision.png"></img>*/}
					
					<div className="vertical-center">
		                           
								<div className="home-dori-plateform-box">
								<ComputerVisionSlider/>
								</div>
							</div>
					</Grid>
					
				</Grid>
			</div>
{/*End leading digital transformation */}
{/* Our Company Values Section Start */}
        <div className="company-values values" style={{ backgroundImage: 'url("../Images/gray-dotted-bg.jpg") '}}>
			<Grid container className="custom-container padding-bottom-96 padding-top-96">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
							<h2 className=" text-center h2-heading company-max-width padding-bottom-16 text-c font-black text-blue">Dori AI enables <span class="text-green"> Data-Driven AI </span>decisions with an end-to-end platform</h2>
							<p className="subtitle text-blue center-subheading text-center padding-bottom-36 text-blue-faint" alignItems="center">Dori AI provides a suite of tools and technologies that enable enterprises with a full-stack solution to address all their AI computer vision needs </p>
				</Grid>
				<Grid container className="grid-outer">
				<Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
                                   
								   <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="database" className="svg-inline--fa fa-user-group svg-icon-color-blue margin-bottom-20 svg-inline--fa fa-database"  role="img"  viewBox="0 0 448 512"><path fill="currentColor" d="M448 73.12v45.75C448 159.1 347.6 192 224 192S0 159.1 0 118.9V73.12C0 32.88 100.4 0 224 0S448 32.88 448 73.12zM448 176v102.9C448 319.1 347.6 352 224 352S0 319.1 0 278.9V176c48.12 33.12 136.2 48.62 224 48.62S399.9 209.1 448 176zM448 336v102.9C448 479.1 347.6 512 224 512s-224-32.88-224-73.13V336c48.12 33.13 136.2 48.63 224 48.63S399.9 369.1 448 336z"></path></svg><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gear" className="svg-inline--fa fa-gear svg-icon-color-blue margin-bottom-20 setting-icon" role="img"  viewBox="0 0 512 512"><path fill="currentColor" d="M499.5 332c0-5.66-3.112-11.13-8.203-14.07l-46.61-26.91C446.8 279.6 448 267.1 448 256s-1.242-23.65-3.34-35.02l46.61-26.91c5.092-2.941 8.203-8.411 8.203-14.07c0-14.1-41.98-99.04-63.86-99.04c-2.832 0-5.688 .7266-8.246 2.203l-46.72 26.98C362.9 94.98 342.4 83.1 320 75.16V21.28c0-7.523-5.162-14.28-12.53-15.82C290.8 1.977 273.7 0 256 0s-34.85 1.977-51.48 5.461C197.2 7.004 192 13.76 192 21.28v53.88C169.6 83.1 149.1 94.98 131.4 110.1L84.63 83.16C82.08 81.68 79.22 80.95 76.39 80.95c-19.72 0-63.86 81.95-63.86 99.04c0 5.66 3.112 11.13 8.203 14.07l46.61 26.91C65.24 232.4 64 244 64 256s1.242 23.65 3.34 35.02l-46.61 26.91c-5.092 2.941-8.203 8.411-8.203 14.07c0 14.1 41.98 99.04 63.86 99.04c2.832 0 5.688-.7266 8.246-2.203l46.72-26.98C149.1 417 169.6 428.9 192 436.8v53.88c0 7.523 5.162 14.28 12.53 15.82C221.2 510 238.3 512 255.1 512s34.85-1.977 51.48-5.461C314.8 504.1 320 498.2 320 490.7v-53.88c22.42-7.938 42.93-19.82 60.65-34.97l46.72 26.98c2.557 1.477 5.416 2.203 8.246 2.203C455.3 431 499.5 349.1 499.5 332zM256 336c-44.11 0-80-35.89-80-80S211.9 176 256 176s80 35.89 80 80S300.1 336 256 336z"></path></svg>  
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Centralize Visual Data Management</h4>
								   <p className="text-blue-faint subtitle">Utilize live or stored visual data from any source and start extracting insights</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
				                   <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="display" className="svg-inline--fa fa-user-group svg-icon-color-blue margin-bottom-20 svg-inline--fa fa-display"  role="img"  viewBox="0 0 576 512"><path fill="currentColor" d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 352H64V64h448V352z"></path></svg><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye" className="svg-inline--fa fa-eye svg-icon-color-blue margin-bottom-20 eye-icon" role="img"  viewBox="0 0 576 512"><path fill="currentColor" d="M572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM432 256c0 79.45-64.47 144-143.9 144C208.6 400 144 335.5 144 256S208.5 112 288 112S432 176.5 432 256zM288 160C285.7 160 282.4 160.4 279.5 160.8C284.8 170 288 180.6 288 192c0 35.35-28.65 64-64 64C212.6 256 201.1 252.7 192.7 247.5C192.4 250.5 192 253.6 192 256c0 52.1 43 96 96 96s96-42.99 96-95.99S340.1 160 288 160z"></path></svg>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Build Robust Computer Vision Applications</h4>
								   <p className="text-blue-faint subtitle">Turn visual data into predictive AI models that can be deployed on the edge or cloud</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
				<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" className="svg-inline--fa fa-circle-check svg-icon-color-blue margin-bottom-20"  role="img"  viewBox="0 0 512 512"><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM371.8 211.8l-128 128C238.3 345.3 231.2 348 224 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L224 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C382.7 183.1 382.7 200.9 371.8 211.8z"></path></svg><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-xmark" className="svg-inline--fa fa-circle-xmark svg-icon-color-blue margin-bottom-20"  role="img"  viewBox="0 0 512 512"><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256C397.4 512 512 397.4 512 256S397.4 0 256 0zM336.1 303c9.375 9.375 9.375 24.56 0 33.94c-9.381 9.381-24.56 9.373-33.94 0L256 289.9l-47.03 47.03c-9.381 9.381-24.56 9.373-33.94 0c-9.375-9.375-9.375-24.56 0-33.94l47.03-47.03L175 208.1c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L256 222.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0s9.375 24.56 0 33.94l-47.03 47.03L336.1 303z"></path></svg>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Make AI-Powered Decisions</h4>
								   <p className="text-blue-faint subtitle">Transform the way your enterprise makes decisions with real-time insights</p>
				</Grid>
				</Grid>
		    </Grid>
		</div>
{/* Our Company Values Section End */}
{/* start One Platform, Endless Insights*/}
			<div className="custom-container section-padding-top  text-center ">
				<Grid container>
					<Grid  item  md={12} className="vertical-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >One Platform,<span style={{color:"#45BEA0"}}> Endless Insights</span></h2>
						    <p className="subtitle font-regular text-blue-faint">Configure and customize AI computer vision applications for all your business needs</p>
						</div>
						<img className="" src="/Images/One-Platform-Endless-Insights.svg" width="100%" height="100%" decoding="async" loading="lazy"></img>
						
					</Grid>
				</Grid>
			</div>
{/* end One Platform, Endless Insights*/}
{/* start case study section*/}
			{/*<div className="custom-container section-padding-top case-study" id="casestudy">
				<Grid container className="text-center">
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >Case Studies</h2>
						    <p className="subtitle font-regular text-blue-faint">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
						</div>
					</Grid>
				</Grid>
				<Grid container className="case-study-inner" spacing={3}>
					<Grid  item  item md={6} xl={6} sm={12} xs={12} className="vertical-center"  alignItems="center"  >
							
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								<Box  order={1}  className="box1">
								<img height="100%" width="100%"className="desktop" src="/Images/Intelligent-visual-inspection-of-assembly-SOPs.jpg" alt="Intelligent visual inspection of assembly SOPs"></img>
									<img height="100%" width="100%"className="mobile" src="/Images/manager-engineer-touch-screen-control-automation-robot-mobile.png" alt="Intelligent visual inspection of assembly SOPs"></img>
								</Box>
								<Box  order={2}  className="box2">
									<div className="inner-text">		
										<h4 className="subtitle font-black padding-bottom-16 text-blue" >Intelligent visual inspection of assembly SOPs</h4>
										<p className="subtitle2 font-regular text-blue-faint">Capturing the actions of each assembly operation from an engine transmission assembly station and automatically flagged.</p>
										<div >
											<ul >
											<li>
												<p className="subtitle font-black padding-bottom-9 text-blue ">50%</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint ">Reduction in maintenance costs  </p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1M+</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Project completed successfully</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">17</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Years of experience</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1000</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">We work with the trusted clients</p>
											</li>
											</ul>
										</div>
										<div className="border"></div>
										<a href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Contact Us</a>
									</div>
								</Box>
							</Box>
						</div>
						
					</Grid>
					<Grid  item  item md={6} xl={6} sm={12} xs={12} className="vertical-center"  alignItems="center"  >
					<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1}  className="box1">
									<img height="100%" width="100%"className="desktop" src="/Images/Intelligent-visual-inspection-of-assembly-SOPs.jpg" alt="Intelligent visual inspection of assembly SOPs"></img>
									<img height="100%" width="100%"className="mobile" src="/Images/manager-engineer-touch-screen-control-automation-robot-mobile.png" alt="Intelligent visual inspection of assembly SOPs"></img>
								</Box>
								<Box  order={2}  className="box2">
									<div className="inner-text">		
										<h4 className="subtitle font-black padding-bottom-16 text-blue" >Intelligent visual inspection of assembly SOPs</h4>
										<p className="subtitle2 font-regular text-blue-faint">Capturing the actions of each assembly operation from an engine transmission assembly station and automatically flagged.</p>
										<div >
											<ul >
											<li>
												<p className="subtitle font-black padding-bottom-9 text-blue ">50%</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint ">Reduction in maintenance costs  </p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1M+</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Project completed successfully</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">17</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Years of experience</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1000</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">We work with the trusted clients</p>
											</li>
											</ul>
										</div>
										<div className="border"></div>
										
										<a href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Contact Us</a>
										{/*form start*/}
										{/*<div id="popup1" className="overlay  contact-us-section section-padding-bottom">
				                            <Grid container className="popup" >
			        
												<Grid  item  item md={5} xl={5} xs={12} className="contact-inner"  alignItems="center"  >
												    <a class="close" href="#casestudy">&times;</a>
													<h2 className="h2-heading text-white font-black padding-bottom-20">Contact Us</h2>
													<p className="subtitle font-regular text-white padding-bottom-36">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.sed diam nonumy eirmod tempor invidunt ut labore.</p>
													<form  >
														<div class="form-row">
															<div class="form-col form-control">
																<input type="text" id="standard-basic" placeholder="First Name" className="subtitle2 font-regular text-white"/>
																<input type="text" placeholder="Last  Name" className="subtitle2 font-regular text-white"  />
															</div>
															<div class=" form-control">
																<input type="text" placeholder="Business Email*" Type="email" className="subtitle2 font-regular text-white" />
															</div>
															<div class="form-control">
																<input type="text" placeholder="Company Name*"   className="subtitle2 font-regular text-white" />
															</div>
															<div class="form-col form-control">
																<input type="text" placeholder="Job Title*"className="subtitle2 font-regular text-white" />
																<input type="text" placeholder="Phone Number" Type="tel" className="subtitle2 font-regular text-white"/>
															</div>
															<div class=" form-control">
																<textarea placeholder="Message" className="subtitle2 font-regular text-white"></textarea>
															</div>
															<div class=" form-control">
																<Button variant="contained" className="rounded-white-btn"><span>Submit</span></Button>
															</div>
														</div>
													</form>
												</Grid>
											</Grid>
										</div>
										{/*End form*/}
									{/*</div>
								{/*</Box>
							</Box>
						</div>
					</Grid>
				</Grid>
  	</div>*/}
    {/* end case study section*/}
    {/* Start trusted by section*/}
    <Trustedbyindustries/>
    {/* End trusted by section*/}
    {/* End trusted by section*/}
    {/* We are Nerd Section Start */}
    <AIPartnerSection/>
    {/* We are Nerd Section end */}
    </section>
		
	);
}
