import React from 'react'
import Button from '@material-ui/core/Button';

const PageNotFound = () => {
    return (
        <div className="custom-container padding-top-96 padding-bottom-96 text-center 404-page" alignItems="center">
            <h1  className="page-404-head text-blue font-black margin-bottom-10">404</h1>
            <img src="/Images/404.svg" alt="dori" decoding="async" loading="lazy" />
            <div id="info">
                <h2  className="h1-heading font-black padding-bottom-16 text-blue">Page Not Found</h2>
                <p className="subtitle font-regular padding-bottom-30 text-blue">Lorem ipsum dolor sit amet, consetetur sadipscing <br></br>elitr, sed diam nonumy eirmod tempor invidunt.</p>
                <Button variant="contained" className="footer-btn" href="/"><span>Back to the Page</span></Button>
            </div>
        </div >
    )
}

export default PageNotFound