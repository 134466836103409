import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Brand = () => {
  return <Link to="/" ><img src="/Images/dori_site_logo.svg" alt="Company Logo" width="109px" height="35px" /></Link>;
};

export default Brand;

const Image = styled.img`
 
  margin: auto 0;
`;
