import React from "react";
import { Container, Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import HubspotForm from 'react-hubspot-form';
import Button from '@material-ui/core/Button';

export default function Footer(props) {
	return (
		<footer className="footer padding-top-96">
			<div className="custom-container" >
				<Grid container    >
				<Grid  item  item md={3} xl={3} sm={3} xs={12} >
				<Link to="/" className="padding-bottom-24 d-block"><img src="/Images/dori_site_logo.svg" alt="dori banner" width="109px" height="35px" className="footerlogo-img"></img></Link>
					<p className="subtitle font-regular padding-bottom-30 text-blue">Dori AI is a full stack end-to-end computer vision and deep learning video analytics platform to significantly increase human productivity and improve process compliance.</p>
	
					<a href="https://www.facebook.com/doriincorporated" className="footer-social-media" target="_blank"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-inline--fa fa-facebook-f svg-icon-color-blue" role="img"  viewBox="0 0 320 512"><path fill="currentColor" d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"></path></svg></a>
					<a href="https://twitter.com/doriaicv" className="footer-social-media" target="_blank"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter svg-icon-twitter" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"></path></svg></a>
					<a href="https://www.linkedin.com/company/dori-ai" className="footer-social-media" target="_blank"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" class="svg-inline--fa fa-linkedin-in" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M100.3 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.6 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.3 61.9 111.3 142.3V448z"></path></svg></a>
				</Grid>
				<Grid  item  item md={1} xl={1} sm={2} xs={12} className="gird"  >
					<div className="footer-first-div ipad">
						<p className="subtitle font-black padding-bottom-16 text-blue">Company</p>
						<ul>
						<li><Link href="/" className="subtitle font-regular link-animation">Home </Link></li>
						<li><Link href="/platform" className="subtitle font-regular link-animation">Platform</Link></li>
            {/*<li><Link href="/blog" className="subtitle font-regular link-animation">Blog</Link></li>*/}
						<li><Link href="/about" className="subtitle font-regular link-animation"> About Dori</Link></li>
						<li><Link href="/careers" className="subtitle font-regular link-animation">Careers</Link></li>
						<li><Link href="/contact" className="subtitle font-regular link-animation">Contact Us</Link></li>
						</ul>
					</div>

				</Grid>
				<Grid  item  item md={2} xl={2} sm={2} xs={12} alignItems="center" className="gird" >
				<div className="footer-first-div">
					<p className="subtitle font-black padding-bottom-16 text-blue">Solutions</p>
						<ul>
						{/*<li><Link to="/solutions" className="subtitle font-regular link-animation">Industry 4.0</Link></li>
						<li><Link to="/smartspaces" className="subtitle font-regular link-animation">Smart Spaces</Link></li>*/}
						<li><Link href="/solutions/manufacturing" className="subtitle font-regular link-animation">Manufacturing</Link></li>
						<li><Link href="/solutions/logistics" className="subtitle font-regular link-animation">Logistics</Link></li>
            <li><Link href="/solutions/siteoperations" className="subtitle font-regular link-animation">Site Operations</Link></li>
						<li><Link href="/solutions/securitysafety" className="subtitle font-regular link-animation">Security & Safety</Link></li>
					</ul>
				</div>

				</Grid>
				{/*<Grid  item  item md={1} xl={1} sm={2} xs={12}  >
				<div className="footer-first-div">
						<p className="subtitle font-black padding-bottom-16 text-blue">Resources</p>
						<ul>
						<li><Link to="/blog" className="subtitle font-regular link-animation">Blog </Link></li>
						<li><Link to="/casestudy" className="subtitle font-regular link-animation">Case Studies</Link></li>
						</ul>
				</div>
					</Grid>*/}
				<Grid  item  item md={3} xl={3} sm={3} xs={12}  >
        {/*
				<div className="footer-first-div">
						<p className="subtitle font-black padding-bottom-16 text-blue">Subscribe to Our Latest Updates</p>
						<p className="subtitle font-regular padding-bottom-16 text-blue">Join our mailing list to stay updated and get notified about our blogs, case studies, articles and product updates</p>
						<HubspotForm
                  region= 'na1'
                  portalId='6819704'
                  formId='052b43c8-53fa-4b27-9c04-f89c5d9fa08e'
                  
                />
						
			   </div>
         */}
				</Grid>
				
				</Grid>
				<div className="footer-border"></div>
				<Grid container className="sub-footer"> 
				<Grid  item  item md={6} xl={6} sm={6} xs={12}  >
					<span><Link href="/termsandconditions" className="subtitle2 font-regular text-blue link-animation">Terms and Conditions </Link></span>
					<span><Link href="/privacypolicy" className="subtitle2 font-regular text-blue link-animation">Privacy Policy </Link></span>
				</Grid>
				<Grid  item  item md={6} xl={6} sm={6} xs={12} >
					<p className="subtitle2 font-regular padding-bottom-16 text-blue copyright">Copyright © 2024 Dori Incorporated. All Rights Reserved. </p>
				</Grid>
				</Grid>
			</div>
		</footer>
	);
}
