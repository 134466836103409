import React from "react";
import './about.css';
import { Grid } from "@material-ui/core";
import Button from '@mui/material/Button'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Link from "@material-ui/core/Link";
import AIPartnerSection from '../components/AIPartnerSection';
export default function About(props) {
	return (

		<>
			{/* Banner Section Start*/ }
			<div className="CommonBanner">
				<div className="CommonBannerImg">
					<img src="/Images/About-Dori-banner.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
				</div>
				<div className="custom-container CommonBannerContent">
					<h1 className="h1-heading padding-bottom-16 font-black">About <span className="text-green">Dori</span></h1>
					<p className="subtitle padding-bottom-30 font-regular">We are on a mission to digitize the physical world through computer vision and bring real-time operational insights to enterprises.</p>
					<Button variant="contained" className="rounded-white-btn" href="/contact"><span>Request Demo</span></Button>
				</div>
			</div>
			{/* Banner Section End */}
		    
			{/* Computer Vision Section Start */}
		    <Grid container className="custom-container section-container">
				<Grid className="computer-vision vertical-center" item md={6} xl={6} xs={12}>
							<h2 className="h2-heading padding-bottom-16 font-black text-blue">Computer Vision Solutions at <span class="text-green d-block">Enterprise Scale</span></h2>
							<p className="subtitle text-blue-faint font-regular">Dori AI enables enterprises with a no-code platform to transform images and videos into actionable insights. Our technology distills visual data into people, processes, and assets and provides businesses with real-time metrics and analytics.</p>
				</Grid>
				<Grid  className="computer-vision-right" item md={6} xl={6} xs={12}>
							<div className="dori-plateform-bg vertical-center" style={{ 
		                           backgroundImage: `url("../Images/blue-dotted-bg.jpg")` }}>
								<div className="dori-plateform-box">
									<img src="/Images/dori_logo.png" alt="Dori-logo"></img>
									<p className="subtitle font-regular">The Dori Platform was built with enterprise considerations including time-to-value, time-to-market, security and scalability across a range of use cases.</p>
								</div>
							</div>
				</Grid>
		    </Grid>
			{/* Computer Vision Section End */}

			{/* Our Company Values Section Start */}
			<Grid container className="custom-container section-padding-bottom our-company-value">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
							<h2 className="h2-heading padding-bottom-16 text-c font-black text-blue">Our <span class="text-green">Company Values</span></h2>
							<p className="subtitle text-blue center-subheading text-center padding-bottom-36 text-blue-faint font-regular" alignItems="center">We value our customers, our strong desire to advance the enterprise world with technology, and our prowess to solve our client's needs.</p>
				</Grid>
				<Grid container className="grid-outer">
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
								   <svg  aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-group" className="svg-inline--fa fa-user-group svg-icon-color-blue margin-bottom-20" role="img"  viewBox="0 0 640 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3c-95.73 0-173.3 77.6-173.3 173.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM479.1 320h-73.85C451.2 357.7 480 414.1 480 477.3C480 490.1 476.2 501.9 470 512h138C625.7 512 640 497.6 640 479.1C640 391.6 568.4 320 479.1 320zM432 256C493.9 256 544 205.9 544 144S493.9 32 432 32c-25.11 0-48.04 8.555-66.72 22.51C376.8 76.63 384 101.4 384 128c0 35.52-11.93 68.14-31.59 94.71C372.7 243.2 400.8 256 432 256z"></path></svg>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Customer First</h4>
								   <p className="text-blue-faint subtitle font-regular">We always put our customer's needs and problems at the forefront of what we do and how we drive our business forward.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
								   
								   <svg className="svg-icon-color-blue margin-bottom-20" viewBox="0 0 512 512"><path d="M496 272C504.8 272 512 264.8 512 256s-7.162-16-16-16H448v-64h48C504.8 176 512 168.8 512 160s-7.162-16-16-16H448V128c0-35.35-28.65-64-64-64h-16V16C368 7.164 360.8 0 352 0c-8.836 0-16 7.164-16 16V64h-64V16C272 7.164 264.8 0 256 0C247.2 0 240 7.164 240 16V64h-64V16C176 7.164 168.8 0 160 0C151.2 0 144 7.164 144 16V64H128C92.65 64 64 92.65 64 128v16H16C7.164 144 0 151.2 0 160s7.164 16 16 16H64v64H16C7.164 240 0 247.2 0 256s7.164 16 16 16H64v64H16C7.164 336 0 343.2 0 352s7.164 16 16 16H64V384c0 35.35 28.65 64 64 64h16v48C144 504.8 151.2 512 160 512c8.838 0 16-7.164 16-16V448h64v48c0 8.836 7.164 16 16 16c8.838 0 16-7.164 16-16V448h64v48c0 8.836 7.164 16 16 16c8.838 0 16-7.164 16-16V448H384c35.35 0 64-28.65 64-64v-16h48c8.838 0 16-7.164 16-16s-7.162-16-16-16H448v-64H496zM270.4 334.7C268.3 335.6 266.2 336 264 336c-6.156 0-12.04-3.578-14.66-9.594L239.5 304H176.5l-9.805 22.41c-3.531 8.125-12.94 11.83-21.06 8.25C137.5 331.1 133.8 321.7 137.3 313.6l56-128c5.062-11.66 24.25-11.66 29.31 0l56 128C282.2 321.7 278.5 331.1 270.4 334.7zM352 320c0 8.844-7.156 16-16 16S320 328.8 320 320V192c0-8.844 7.156-16 16-16S352 183.2 352 192V320zM190.5 272h35.07L208 231.9L190.5 272z"/></svg>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Advance Technology</h4>
								   <p className="text-blue-faint subtitle font-regular">We strive to always be ahead of the curve on all technologies to ensure we leverage and advance the knowledge and expertise of the community.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
								 
								  <svg className="svg-icon-color-blue margin-bottom-20" viewBox="0 0 640 512"><path d="M0 383.9l64 .0404c17.75 0 32-14.29 32-32.03V128.3L0 128.3V383.9zM48 320.1c8.75 0 16 7.118 16 15.99c0 8.742-7.25 15.99-16 15.99S32 344.8 32 336.1C32 327.2 39.25 320.1 48 320.1zM348.8 64c-7.941 0-15.66 2.969-21.52 8.328L228.9 162.3C228.8 162.5 228.8 162.7 228.6 162.7C212 178.3 212.3 203.2 226.5 218.7c12.75 13.1 39.38 17.62 56.13 2.75C282.8 221.3 282.9 221.3 283 221.2l79.88-73.1c6.5-5.871 16.75-5.496 22.62 1c6 6.496 5.5 16.62-1 22.62l-26.12 23.87L504 313.7c2.875 2.496 5.5 4.996 7.875 7.742V127.1c-40.98-40.96-96.48-63.88-154.4-63.88L348.8 64zM334.6 217.4l-30 27.49c-29.75 27.11-75.25 24.49-101.8-4.371C176 211.2 178.1 165.7 207.3 138.9L289.1 64H282.5C224.7 64 169.1 87.08 128.2 127.9L128 351.8l18.25 .0369l90.5 81.82c27.5 22.37 67.75 18.12 90-9.246l18.12 15.24c15.88 12.1 39.38 10.5 52.38-5.371l31.38-38.6l5.374 4.498c13.75 11 33.88 9.002 45-4.748l9.538-11.78c11.12-13.75 9.036-33.78-4.694-44.93L334.6 217.4zM544 128.4v223.6c0 17.62 14.25 32.05 31.1 32.05L640 384V128.1L544 128.4zM592 352c-8.75 0-16-7.246-16-15.99c0-8.875 7.25-15.99 16-15.99S608 327.2 608 336.1C608 344.8 600.8 352 592 352z"/></svg>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Stay Focused</h4>
								   <p className="text-blue-faint subtitle font-regular">We are determined and dedicated to applying the right technologies and solutions to serve our customers' needs.</p>
				</Grid>
				</Grid>
		    </Grid>
			{/* Our Company Values Section End */}

			{/* Our Mission Our Vision Section Start */}
			<div className="our-mission-vision padding-top-96" style={{ 
		                           backgroundImage: `url("../Images/gray-dotted-bg.jpg")` }}>
			<Grid container className="custom-container" >
				<Grid  variant="outlined" item md={6} xl={6} xs={12}>
					<h3 className="h3-heading padding-bottom-16 text-blue font-bold">Our Mission</h3>
					<h4 className="text-blue-faint h4-heading padding-bottom-16 our-mision-subheading font-regular">Empowering enterprises with visibility to transform and advance their business operations.</h4>
				</Grid>
				<Grid  variant="outlined" item md={6} xl={6} xs={12}>
					<p className="text-blue-faint subtitle padding-bottom-30 font-regular">Our goal is to leverage bleeding edge technologies such as AI and computer vision to enable our customers with robust solutions that meet their business needs and elevate their day-to-day operations to the next level of productivity, efficiency, quality, and compliance.</p>
					<p className="text-blue-faint subtitle">AI evolves and so do we. We are a firm dedicated to ensuring the right solution makes its way into our customer's hands. Technologies are tools that continue to advance and we strive to always stay ahead of the curve to ensure the best technologies are adopted by enterprises.</p>
				</Grid>
				<Grid  variant="outlined" className="padding-top-bottom-60" item md={12} xl={12} xs={12}>
                    <div class="short-divider" alignItems="center">
					</div>
				</Grid>

				<Grid container direction="row" alignItems="center" className="vertical-center padding-bottom-96" alignItems="center" item md={12} xl={12} xs={12}>
				    <h3 className="h3-heading padding-bottom-16 text-blue font-bold">Our Vision</h3>
					<p className="subtitle text-blue center-subheading over-mission-subheading text-center text-blue-faint" alignItems="center">We strive to be the leading AI computer vision company that enterprises turn to for their AI digital transformation efforts. We are dedicated to ensuring our team is equipped with the knowledge, expertise, and will to serve our customers' needs.</p>
				</Grid>
		    </Grid>
			</div>
			{/* Our Mission Our Vision Section end */}


			{/* How are we Different Section Start*/}
			<Grid container className="custom-container section-padding-top how-we-are">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
							<h2 className="h2-heading padding-bottom-36 text-c font-black text-blue">How are we <span class="text-green">Different? </span></h2>
				</Grid>
				<Grid container className="grid-outer">
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
                                   <div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>01</span></div>  
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Our Approach</h4>
								   <p className="text-blue-faint subtitle font-regular">AI and computer vision are not just about deploying models - we take a holistic systems approach to solving our customer's problems.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>02</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Our Team </h4>
								   <p className="text-blue-faint subtitle font-regular">We pride ourselves in putting together a team of engineers, specialists, and dreamers that bring a diverse set of knowledge and expertise to the table.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>03</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Our Experience</h4>
								   <p className="text-blue-faint subtitle font-regular">Dori AI's team brings together leading experts in the field of AI, CV, and hardware/software systems that are essential to build robust solutions.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>04</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Our Technology</h4>
								   <p className="text-blue-faint subtitle font-regular">We have invented and built bleeding edge technologies that advance our customer's operations to the next level.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>05</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Our Drive</h4>
								   <p className="text-blue-faint subtitle font-regular">We continue to push the boundaries of technology in order to better serve our customers' ever changing needs.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} xs={12}>
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>06</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Our Responsibility</h4>
								   <p className="text-blue-faint subtitle font-regular">Leveraging AI and computer vision is our core, but it's our commitment to ensure the technology is used responsibly and appropriately.</p>
				</Grid>	
				</Grid>
		    </Grid>
			{/* How are we Different Section End*/}

			{/* Meet our Founding Team Section Start */}
			<Grid  variant="outlined" className="padding-top-96 padding-bottom-96" item md={12} xl={12} xs={12}>
                    <div class="short-divider short-divider-80" alignItems="center">
					</div>
			</Grid>
			<Grid container className="custom-container our-team section-padding-bottom">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
					<h2 className="h2-heading padding-bottom-16 text-c font-black text-blue text-center">Meet our<span class="text-green"> Founding Team </span></h2>
					<p className="subtitle text-blue center-subheading text-center padding-bottom-36 text-blue-faint font-regular" alignItems="center">Dori AI is led by some of the world's leading technology and AI experts. Get to know our team.</p>
				</Grid>
				<Grid container className="grid-outer">
					<Grid container variant="outlined" item md={4} xl={4} xs={12}>
						<img src="/Images/Vivek-Bansal.png" alt="Vivek-Bansal" className="profilePic margin-bottom-16" decoding="async" loading="lazy" ></img>
						<h4 className="h4-heading padding-bottom-10 text-blue-faint font-bold teamMemberName">Vivek-Bansal</h4>
						<p className="text-blue-faint subtitle font-regular padding-bottom-16" >CEO</p>
						<Link href="https://www.linkedin.com/in/vivekbansal1">
						     <LinkedInIcon className="linked-in-icon text-c"  fontSize="small" color="primary" />
                        </Link> 
						
					</Grid>
					<Grid container variant="outlined" item md={4} xl={4} xs={12}>
						<img src="/Images/Ravi-Lambi.png" alt="Ravi-Lambi" className="profilePic margin-bottom-16" ></img>
						<h4 className="h4-heading padding-bottom-10 text-blue-faint font-bold teamMemberName">Ravi Lambi</h4>
						<p className="text-blue-faint subtitle font-regular padding-bottom-16" >VP Engineering</p>
						<Link href="https://www.linkedin.com/in/ravilambi">
						     <LinkedInIcon className="linked-in-icon text-c"  fontSize="small" color="primary" />
                        </Link>
					</Grid>
					<Grid container variant="outlined" item md={4} xl={4} xs={12}>
						<img src="/Images/Nitin-Gupta.png" alt="Nitin-Gupta" className="profilePic margin-bottom-16" decoding="async" loading="lazy" ></img>
						<h4 className="h4-heading padding-bottom-10 text-blue-faint font-bold teamMemberName">Dr. Nitin Gupta, Ph.D.</h4>
						<p className="text-blue-faint subtitle font-regular padding-bottom-16" >VP Product</p>
						<Link href="https://www.linkedin.com/in/dr-nitin-gupta-ph-d-a37b0289">
						     <LinkedInIcon className="linked-in-icon text-c"  fontSize="small" color="primary" />
                        </Link>
					</Grid>
			    </Grid>
		    </Grid>
			{/* Meet our Founding Team Section End */}

			{/* We are Nerd Section Start */}
      {/*
			<div className="we-are-nerd padding-top-96 padding-bottom-96 bg-pattern margin-bottom-124 section-container" style={{ 
		                           backgroundImage: `url("../Images/gray-dotted-bg.jpg")` }}>
                <Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
							<h2 className="h1-heading padding-bottom-16 text-c font-black text-blue text-center">We are <span class="text-green">Nerd Rockstars</span></h2>
							<p className="subtitle text-blue center-subheading text-center padding-bottom-30 text-blue-faint font-regular " alignItems="center">We are a group of AI/ML and computer vision experts, system engineers, entrepreneurs, data scientists, futurists, and more. Building complex systems that help improve the lives of millions of people gets us energized.</p>
							<p className="subtitle text-blue center-subheading text-center text-blue-faint font-regular padding-bottom-30" alignItems="center"> Interested in starting or continuing your career in AI and computer vision? Become part of our team or email us directly at careers@dori.ai and let us know how you can help the team.</p>
							<Button variant="contained" className="rounded-white-btn" href="/careers"><span>Explore Career Opportunities</span></Button>
				</Grid>
			</div>
      */}
      {/* We are Nerd Section end */}


			{/* Our Trusted & Technology Partnerships Section Start */}
			{/*<Grid container className="custom-container technology-partnerships section-container">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
					<h2 className="h2-heading padding-bottom-16 text-c font-black text-blue text-center">Our <span class="text-green"> Trusted & Technology </span>Partnerships</h2>
					<p className="subtitle text-blue center-subheading text-center padding-bottom-36 text-blue-faint font-regular" alignItems="center">Dori Ai is helping global brands to significantly increase human productivity & improve process compliances.</p>
				</Grid>
				<Grid container className="grid-outer">
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/AWS.svg" alt="AWS" width="54px" height="32px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Microsoft Azure.svg" alt=" Azure" width="61px" height="33px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Google-cloud-platform-v2.svg" alt="Google-cloud-platform" width="37px" height="32px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" width="104px" height="20px" item md={4} xl={4} xs={12}>
						<img src="/Images/NVIDIA.svg" alt="NVIDIA" ></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" width="54px" height="36px"  item md={4} xl={4} xs={12}>
						<img src="/Images/intel.svg" alt="intel"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Plug-and-Play.svg" alt="Plug-and-Play" width="96px" height="18px"></img>
					</Grid>
			    </Grid>
		    </Grid>*/}
			{/* Our Trusted & Technology Partnerships Section End */}


			{/* End trusted by section*/}
			{/* We are Nerd Section Start */}
			<AIPartnerSection/>
{/* We are Nerd Section end */}

			
						
	    </>
		
		
	);
}



