import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Career.css";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import CurrentOpeningSection from '../components/CurrentOpeningSection';
export default function Career(props) {
	return (
                <>
 {/*Start banner section */}
                    <div className="CommonBanner">
                    <div className="CommonBannerImg">
                        <img src="/Images/Careers_banner.jpg" alt="Career" width="100%" height="100%" decoding="async" loading="lazy"></img>
                    </div>
                    <div className="custom-container CommonBannerContent">
                        <h1 className="h1-heading padding-bottom-16 font-black">Careers at <span className="text-green"> Dori AI</span></h1>
                        <p className="subtitle padding-bottom-30 font-regular">Come be a part of the most amazing team that is building bleeding edge AI and computer vision technologies.</p>
                        <a href="#current-opening"><Button variant="contained" className="rounded-white-btn" ><span>Apply Now</span></Button></a>
                    </div>
                </div>
                
{/*end banner section */}
{/* start overview section */}
<div className="custom-container section-padding-top   overview">
				<Grid container spaciing={6}>
					<Grid  item  md={6} className="vertical-center"  >
						<div className="inner-div ">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >We are <span className="text-green">Nerd Rockstars</span> </h2>
						 	<p className="subtitle font-regular padding-bottom-16 text-blue-faint">We are a group of AI/ML and computer vision experts, system engineers, entrepreneurs, data scientists, futurists, and more. Building complex systems that help improve the lives of millions of people gets us energized. </p>
                            <p className="subtitle font-regular padding-bottom-16 text-blue-faint">Interested in starting or continuing your career in AI and computer vision? Become part of our team or email us directly at careers@dori.ai and let us know how you can help the team.</p>            
                        </div>
					</Grid>
				 	<Grid  item  md={6} >
                         {<img src="/Images/Overview.jpg" alt="Overview" decoding="async" loading="lazy"></img>}
					</Grid>
					
				</Grid>
			</div>
{/*End Overview section */}
{/*start tab section */}
<div className="custom-container section-padding-top  section-padding-bottom " id="current-opening">
	
                <Grid container className="" alignItems="center">
					<Grid  item  md={12} className="vertical-center"  alignItems="center" >		
							<h2 className="h2-heading font-black padding-bottom-36 text-blue" >Current Openings </h2>
						
					</Grid>
				</Grid>
                    <CurrentOpeningSection />
</div>
{/*End tab section */}
{/* How we are Different? Start*/}
<div style={{ backgroundImage: 'url("../Images/blue-dotted-bg.jpg") '}} className="How-we-are-Different">
<Grid container className="custom-container padding-top-96 padding-bottom-96" >
				<Grid container direction="row" alignItems="center"  className="vertical-center" item md={12} xl={12} sm={12} xs={12}>
							<h2 className="h2-heading padding-bottom-16 text-c font-black text-white">How we are <span class="text-green">Different?</span></h2>
				           {/* <p className="subtitle text-center font-regular text-white inner">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>*/}
				</Grid>
				<Grid container className="grid-outer" >
				<Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="grid-inner">
                                   <div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>01</span></div>  
								   <h4 className="h4-heading padding-bottom-14 text-white font-bold">Our Approach</h4>
								   <p className="text-white subtitle font-regular">AI and computer vision are not just about deploying models - we take a holistic systems approach to solving our customer's problems.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="grid-inner">
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>02</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-white font-bold">Our Team</h4>
								   <p className="text-white subtitle font-regular">We pride ourselves in putting together a team of engineers, specialists, and dreamers that bring a diverse set of knowledge and expertise to the table.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="grid-inner">
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>03</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-white font-bold">Our Experience</h4>
								   <p className="text-white subtitle font-regular">Dori AI's team brings together leading experts in the field of AI, CV, and hardware/software systems that are essential to build robust solutions.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="grid-inner">
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>04</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-white font-bold">Our Technology</h4>
								   <p className="text-white subtitle font-regular">We have invented and built bleeding edge technologies that advance our customer's operations to the next level.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="grid-inner">
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>05</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-white font-bold">Our Drive</h4>
								   <p className="text-white subtitle font-regular">We continue to push the boundaries of technology in order to better serve our customers' ever changing needs.</p>
				</Grid>
				<Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="grid-inner">
				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span>06</span></div>
								   <h4 className="h4-heading padding-bottom-14 text-white font-bold">Our Responsibility</h4>
								   <p className="text-white subtitle font-regular">Leveraging AI and computer vision is our core, but it's our commitment to ensure the technology is used responsibly and appropriately.</p>
				</Grid>
			</Grid>
		    </Grid>
            </div>
{/* How we are Different? End*/}

{/* start testomonials section */}
{/*<div className="custom-container section-padding-top section-padding-bottom  testomonials">
				<Grid container className="">
					<Grid  item  md={12} className="vertical-center"  alignItems="center" >		
							<h2 className="h2-heading font-black padding-bottom-36 text-blue" >Testimonials </h2>
						
					</Grid>
				</Grid>
				<Grid container  className="grid-outer"> 
				 	<Grid  item  xl={6} md={6} xs={12} className="test-inner" >
					 <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-right" className="svg-inline--fa fa-quote-right margin-bottom-30" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M96 96C42.98 96 0 138.1 0 192s42.98 96 96 96c11.28 0 21.95-2.305 32-5.879V288c0 35.3-28.7 64-64 64c-17.67 0-32 14.33-32 32s14.33 32 32 32c70.58 0 128-57.42 128-128V192C192 138.1 149 96 96 96zM448 192c0-53.02-42.98-96-96-96s-96 42.98-96 96s42.98 96 96 96c11.28 0 21.95-2.305 32-5.879V288c0 35.3-28.7 64-64 64c-17.67 0-32 14.33-32 32s14.33 32 32 32c70.58 0 128-57.42 128-128V192z"></path></svg>
						 <p className="h4-heading padding-bottom-30 text-blue font-bold">“Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore."</p>
						 <p className="subtitle text-blue-faint font-bold">Gabrielle Greene, ENGINEERING MANAGER</p>
					</Grid>
					<Grid  item xl={6}  md={6} xs={12} className="test-inner"> 
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-right" className="svg-inline--fa fa-quote-right margin-bottom-30" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M96 96C42.98 96 0 138.1 0 192s42.98 96 96 96c11.28 0 21.95-2.305 32-5.879V288c0 35.3-28.7 64-64 64c-17.67 0-32 14.33-32 32s14.33 32 32 32c70.58 0 128-57.42 128-128V192C192 138.1 149 96 96 96zM448 192c0-53.02-42.98-96-96-96s-96 42.98-96 96s42.98 96 96 96c11.28 0 21.95-2.305 32-5.879V288c0 35.3-28.7 64-64 64c-17.67 0-32 14.33-32 32s14.33 32 32 32c70.58 0 128-57.42 128-128V192z"></path></svg>
						 <p className="h4-heading padding-bottom-30 text-blue font-bold">“Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore."</p>
						 <p className="subtitle text-blue-faint font-bold">Gabrielle Greene, ENGINEERING MANAGER</p>
					</Grid>
					
				</Grid>
				
			</div>*/}
			
		
{/*End testomonials section */}
       </>
        );
}