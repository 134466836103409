import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Job.css";

import Link from "@material-ui/core/Link";
import HubspotForm from 'react-hubspot-form';
export default function FrontendDeveloper(props) {
    
	return (
        <div className="custom-container job-page">
            <Link href="/careers" className="subtitle2 link">Back</Link>
        <div className=" padding-top-bottom-60 ">
            <Grid container spaciing={6}>
					<Grid  item  md={7} sm={12} xs={12} className="vertical-center"  >
						<div className="inner-div ">		
							<h2 className="h2-heading font-black margin-bottom-10 text-blue" >Frontend Developer</h2>
						 	<p className="subtitle font-regular text-blue"> 4+ yrs | Bengaluru North, Karnataka, India</p>
                            <div className="job-border"></div>
                            <div className="job-desciption ">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Responsibilities:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Participate heavily in brainstorming of system architecture and feature design</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Take UI Mocks from design team and provide properly styled, pixel accurate dashboards in React JS using standard component libraries and custom components</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Architect and create reusable, effective, and scalable JavaScript code/components</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Use additional JavaScript libraries such as Redux to manage the web application's state, derive computed data from the state of the system, and make asynchronous API calls</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Work cross functionally with Engineering, Data Science, Product, UX, BD, and Infrastructure teams</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Drive best coding practices across the company (i.e. documentation, code reviews, coding standards, etc)</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Complete projects with little or no supervision from senior leadership</li>
                                      <li  className="subtitle font-regular text-blue-faint ">Ability to adapt quickly to changing design requirements/designs and company priorities</li>
                              
                                  </ul>    
                            </div>   
                            <div className="job-desciption Requirements">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Required Qualifications:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Must have at least 4+ years experience in front-end development with the the following tools: ReactJS, HTML5, CSS, NodeJS</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Preceding experience with ReactJS workflows and libraries like Flux, Redux, Create React App, data structure libraries, Material UI/Bootstrap, VideoJS, JSON libraries</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience building dashboards with rich media (image galleries, video playback)</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Strong proficiency in JavaScript, including DOM manipulation, event handlers, component lifecycle, webhooks, and the JavaScript object model</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Proficient in RESTful API development and working with backend servers (i.e. Python/Flask)</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Built and deployed customer-facing services and products at scale</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Developed unit and integration tests</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience using Postman for API testing, test automation using Selenium, and front-end performance testing frameworks</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Worked on products where experimentation and data science are core to development</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience with large-scale distributed systems that have thousands of micro services and manages millions of transactions per day</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Solid instruction-level understanding of Object Oriented design, data structures, and software engineering principles</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Must have experience using Git for code review and version control</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience with the following tools desirable: AWS Tools (Amplify/S3/EC2)</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience with open source projects in a startup environment desirable.</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Skills:- Javascript, React.js and Redux/Flux</li>
                                  
                                  </ul>    
                            </div>    
                            <div className="job-desciption Details">
                                  <ul>
                                  <li className="subtitle font-regular padding-bottom-16 text-blue" ><b>Seniority level:</b>
                                  <p>Director</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue">  <b>Employment type:</b>
                                    <p>Full-time</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"> <b>Job function:</b>
                                    <p>Engineering and Information Technology</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"><b>Industries:</b>
                                    <p>IT Services and IT Consulting, Software Development, and Internet Publishing</p></li>
                                </ul>    
                            </div>        
                        </div>
					</Grid>
                    {/*<Grid  item  md={5} sm={12} xs={12} className=""  >
						<div className=" ">	
                        <h2 className="h2-heading font-black padding-bottom-36 text-blue" >Application Form</h2>	
						  <ApplicationForm/>  
                          
                           <HubspotForm
                             region= 'na1'
                  portalId='6819704'
                  formId='1af92a5c-b450-483a-b450-f7edb713e64b'
                 
                />

        
                        </div>
					</Grid>*/}
            </Grid>

        </div>
    </div>
    );

}