import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Solution.css";
import ReactDOM from 'react-dom';
import Box from '@material-ui/core/Box';
import Link from "@material-ui/core/Link";
import Button from '@mui/material/Button';
import HowIiWorksTab from '../components/HowIiWorksTab';
import HubspotForm from 'react-hubspot-form';
import Trustedbyindustries from '../components/Trustedbyindustries';
import AIPartnerSection from '../components/AIPartnerSection';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function SecurityAndSafety(props) {
	var settings = {
		dots: false,
		infinite: true,
		vertical: false,
		autoplay:true,
		fade:true,
		arrows: false,
		speed: 4000,
 infinite: true,
 cssEase: 'ease-in-out',
 touchThreshold: 100
	  };
  return( 
    <>
 {/*Start banner section */}
  <div className="CommonBanner">
        <div className="CommonBannerImg">
		<Slider {...settings}>
		<div>
            <img src="../Images/Security-Safety-Banner.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
		</div>
		<div>
            <img src="../Images/Security-Safety-Banner-two.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
		</div>
		<div>
            <img src="../Images/Security-Safety-Banner-three.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
		</div>
		<div>
            <img src="../Images/Security-Safety-Banner-four.jpg" alt="About-Dori" width="100%" height="100%" decoding="async" loading="lazy"></img>
		</div>
	
		</Slider>
        </div>
        <div className="custom-container CommonBannerContent">
                <h1 className="h1-heading padding-bottom-16 font-black">Enabling <span className="text-green"> Digital Transformations </span> for Safety & Security</h1>
                <p className="subtitle padding-bottom-30 font-regular">Dori AI provides enterprises with a suite of applications to address the needs of productivity, compliance, quality, and safety</p>
                <Button variant="contained" className="rounded-white-btn" href="/contact"><span>Request Demo</span></Button>
        </div>
  </div>
{/*end banner section */}
{/* Visual Intelligence Platform for Enterprise Start */}
<div className="company-values Intelligence-Platform">
		<Grid container className="custom-container  section-padding-top">
          <Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
                <h2 className=" text-center h2-heading platform-max-width padding-bottom-36 font-black text-blue"><span class="text-green">Visual Intelligence Platform </span>for Enterprises</h2>
          </Grid>
          <Grid container className="grid-outer">
                <Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
                                          
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye" className="svg-inline--fa fa-eye svg-icon-color-blue margin-bottom-20 " role="img"  viewBox="0 0 576 512"><path fill="currentColor" d="M572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM432 256c0 79.45-64.47 144-143.9 144C208.6 400 144 335.5 144 256S208.5 112 288 112S432 176.5 432 256zM288 160C285.7 160 282.4 160.4 279.5 160.8C284.8 170 288 180.6 288 192c0 35.35-28.65 64-64 64C212.6 256 201.1 252.7 192.7 247.5C192.4 250.5 192 253.6 192 256c0 52.1 43 96 96 96s96-42.99 96-95.99S340.1 160 288 160z"></path></svg>  
                          <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Site Visibility</h4>
                          <p className="text-blue-faint subtitle">Monitor workforce or visitor movements to improve smooth operations</p>
                </Grid>

                <Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
                                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="display" className="svg-inline--fa fa-user-group svg-icon-color-blue margin-bottom-20 svg-inline--fa fa-display"  role="img"  viewBox="0 0 576 512"><path fill="currentColor" d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 352H64V64h448V352z"></path></svg>
                          <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Process Monitoring</h4>
                          <p className="text-blue-faint subtitle">Ensure all SOPs and operations are compliant and measure effectiveness</p>
                </Grid>
                <Grid  variant="outlined" item md={4} xl={4} xs={12} style={{backgroundColor:'#ffffff'}}>
				
				<svg className="svg-icon-color-blue margin-bottom-20" viewBox="0 0 448 512"><path d="M224 112c-53.04 0-96 42.96-96 96c0 53.04 42.96 96 96 96s96-42.96 96-96C320 154.1 277 112 224 112zM384 0h-320c-35.38 0-64 28.62-64 64v288c0 35.38 28.62 64 64 64h72.75L76.5 450.9C69.63 455.1 64 465.3 64 473.4v12C64 500 76 512 90.75 512h266.6C372.1 512 384 500 384 485.4V473.5c0-8.25-5.625-18.38-12.62-22.62L311.3 416h72.75c35.38 0 64-28.62 64-64V64C448 28.62 419.4 0 384 0zM224 336c-70.78 0-127.1-57.33-127.1-128c0-70.67 57.33-128 127.1-128s128 57.33 128 128C352 278.7 294.7 336 224 336z"/></svg>      
						  <h4 className="h4-heading padding-bottom-14 text-blue font-bold">Site Security + Safety</h4>
                          <p className="text-blue-faint subtitle">Track all visitors, personnel, assets, and equipment to ensure no risks or hazards</p>
                </Grid>
          </Grid>
		</Grid>
		</div>
{/* Visual Intelligence Platform for Enterprise End */}
{/* Start Key-Use-Cases*/}
<div className="custom-container section-padding-top Key-Use-Cases  section-padding-bottom">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >Key Use Cases</h2>
						    <p className="subtitle font-regular text-blue-faint">Dori AI enables enterprises with immense business value across a range of key use cases and many more. </p>
						</div>
					</Grid>
				<Grid  container spacing={3}>
                <Grid  item  item md={6} xl={6} sm={12}xs={12} className="vertical-center"  alignItems="center"  >
					    <div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								<Box  order={1}  className="trusted-box1">
									<img height="100%" width="100%"className="desktop" src="../Images/Perimeter-Zone-Access.jpg" alt="Perimeter & Zone Access" decoding="async" loading="lazy"></img>
									<img height="100%" width="100%"className="mobile" src="../Images/Perimeter-Zone-Access-mobile.jpg" alt="Perimeter & Zone Access" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Perimeter & Zone Access</h4>
										<p className="subtitle2 font-regular text-blue-faint">Monitor presence of employees or visitors in restricted areas and flag alerts when necessary</p>
										 <div className="border-inner"></div>
										<Link href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
				<Grid  item  item md={6} xl={6} sm={12}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1">
									<img height="100%" width="100%"className="desktop" src="../Images/PPE_Compliance_desktop.jpg" alt="PPE Compliance" decoding="async" loading="lazy"></img>
									<img height="100%" width="100%"className="mobile" src="../Images/PPE_Compliance_mobile.jpg" alt="PPE Compliance" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >PPE Compliance</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Ensure all employees and/or visitors are wearing appropriate safety equipment and attire</p>
									    <div className="border-inner"></div>
										<Link href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					
			   		 <Grid  item  item md={6} xl={6} sm={12}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								<Box  order={1} className="trusted-box1">
									<img height="100%" width="100%"className="desktop" src="../Images/Equipment-Access-&-Safety-desktop.jpg" alt="Worker Safety" decoding="async" loading="lazy"></img>
									<img height="100%" width="100%"className="mobile" src="../Images/Equipment-Access-&-Safety-mobile.jpg" alt="Worker Safety" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Worker Safety</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Monitor for risks and incidents to maintain a safe and secure environment for all personnel</p>
										<div className="border-inner"></div>
										<Link href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={6} xl={6} sm={12}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1">
									<img height="100%" width="100%"className="desktop" src="../Images/Safety-Hazard-Monitoring-desktop.jpg" alt="Safety Hazard Monitoring" decoding="async" loading="lazy"></img>
									<img height="100%" width="100%"className="mobile" src="../Images/Safety-Hazard-Monitoring-mobile.jpg" alt="Safety Hazard Monitoring" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Hazard Monitoring</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Flag and alert immediately whenever a safety hazard occurs (i.e. spill, smoke, fire, etc)</p>
										<div className="border-inner"></div>
										<Link href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					
				</Grid>
				</Grid>
			</div>
{/* End Key-Use-Cases*/}
{/* Our Key Results Start */}
<div className="Key_Results" style={{ backgroundImage: 'url("../Images/gray-dotted-bg.jpg") '}}>
			<Grid container className="custom-container padding-bottom-96 padding-top-96">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
							<h2 className=" text-center h2-heading company-max-width padding-bottom-30 text-c font-black text-blue">Delivering Key Results for<br></br> <span class="text-green">All Enterprises </span></h2>
				</Grid>
				<Grid  container spacing={3}>
				 <Grid  item  md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg  className="gear svg-icon-color-blue "viewBox="0 0 512 512"><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64C238.3 64 224 78.33 224 96C224 113.7 238.3 128 256 128C273.7 128 288 113.7 288 96C288 78.33 273.7 64 256 64zM256 416C291.3 416 320 387.3 320 352C320 348.3 319.7 344.7 319.1 341.2L436.6 268.4C447.9 261.4 451.4 246.6 444.4 235.4C437.4 224.1 422.6 220.6 411.4 227.6L293.8 300.4C283.2 292.6 270.1 288 256 288C220.7 288 192 316.7 192 352C192 387.3 220.7 416 256 416L256 416zM144 112C126.3 112 112 126.3 112 144C112 161.7 126.3 176 144 176C161.7 176 176 161.7 176 144C176 126.3 161.7 112 144 112zM96 288C113.7 288 128 273.7 128 256C128 238.3 113.7 224 96 224C78.33 224 64 238.3 64 256C64 273.7 78.33 288 96 288zM368 112C350.3 112 336 126.3 336 144C336 161.7 350.3 176 368 176C385.7 176 400 161.7 400 144C400 126.3 385.7 112 368 112z"/></svg>
								
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Increased Operational Efficiency</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
						         <img src="../Images/Reduced-Perimeter-Breaches.svg" alt="Reduced Perimeter Breaches" width="48" height="auto" decoding="async" loading="lazy"></img>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Reduced Perimeter Breaches </p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  md={4} xl={4} sm={6} xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg  className="gear svg-icon-color-blue  " viewBox="0 0 448 512"><path d="M224 320c65.25 0 118.5-49 126.4-112H97.63C105.5 271 158.8 320 224 320zM88 176h272c4.375 0 8-3.625 8-8V136c0-4.375-3.625-8-8-8H352c0-45-26.88-85.62-68.38-103.2L256 80V16c0-8.875-7.125-16-16-16h-32c-8.875 0-16 7.125-16 16V80L164.4 24.75C122.9 42.38 96 83 96 128H88c-4.375 0-8 3.625-8 8v31.1C80 172.4 83.63 176 88 176zM314.7 352H133.3C59.7 352 0 411.7 0 485.3c0 14.73 11.94 26.67 26.66 26.67H421.3c14.72 0 26.66-11.94 26.66-26.67C448 411.7 388.3 352 314.7 352z"/></svg>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Safer Work Environment</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  md={4} xl={4} sm={6} xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg  className="gear svg-icon-color-blue " viewBox="0 0 576 512"><path d="M568.2 336.3c-13.12-17.81-38.14-21.66-55.93-8.469l-119.7 88.17h-120.6c-8.748 0-15.1-7.25-15.1-15.99c0-8.75 7.25-16 15.1-16h78.25c15.1 0 30.75-10.88 33.37-26.62c3.25-20-12.12-37.38-31.62-37.38H191.1c-26.1 0-53.12 9.25-74.12 26.25l-46.5 37.74L15.1 383.1C7.251 383.1 0 391.3 0 400v95.98C0 504.8 7.251 512 15.1 512h346.1c22.03 0 43.92-7.188 61.7-20.27l135.1-99.52C577.5 379.1 581.3 354.1 568.2 336.3zM279.3 175C271.7 173.9 261.7 170.3 252.9 167.1L248 165.4C235.5 160.1 221.8 167.5 217.4 179.1s2.121 26.2 14.59 30.64l4.655 1.656c8.486 3.061 17.88 6.095 27.39 8.312V232c0 13.25 10.73 24 23.98 24s24-10.75 24-24V221.6c25.27-5.723 42.88-21.85 46.1-45.72c8.688-50.05-38.89-63.66-64.42-70.95L288.4 103.1C262.1 95.64 263.6 92.42 264.3 88.31c1.156-6.766 15.3-10.06 32.21-7.391c4.938 .7813 11.37 2.547 19.65 5.422c12.53 4.281 26.21-2.312 30.52-14.84s-2.309-26.19-14.84-30.53c-7.602-2.627-13.92-4.358-19.82-5.721V24c0-13.25-10.75-24-24-24s-23.98 10.75-23.98 24v10.52C238.8 40.23 221.1 56.25 216.1 80.13C208.4 129.6 256.7 143.8 274.9 149.2l6.498 1.875c31.66 9.062 31.15 11.89 30.34 16.64C310.6 174.5 296.5 177.8 279.3 175z"/></svg>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Lower Liability Costs</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  md={4} xl={4} sm={6} xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box ">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg className="gear svg-icon-color-blue " viewBox="0 0 512 512"><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 184.4 29.46 119.6 76.84 73.14C89.46 60.78 109.7 60.98 122.1 73.6C134.5 86.23 134.3 106.5 121.6 118.9C86.03 153.7 64 202.3 64 256C64 362 149.1 448 256 448C362 448 448 362 448 256C448 160.9 378.8 81.89 288 66.65V96C288 113.7 273.7 128 256 128C238.3 128 224 113.7 224 96V32C224 14.33 238.3 0 256 0C397.4 0 512 114.6 512 256zM272.1 239C282.3 248.4 282.3 263.6 272.1 272.1C263.6 282.3 248.4 282.3 239 272.1L159 192.1C149.7 183.6 149.7 168.4 159 159C168.4 149.7 183.6 149.7 192.1 159L272.1 239z"/></svg>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Improved Response Times</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item   md={4} xl={4} sm={6} xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1} className="trusted-box1 vertical-center">
								<svg  className=" gear svg-icon-color-blue "  viewBox="0 0 576 512"><path d="M466.7 352L320 205.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6L9.372 150.6C-3.124 138.1-3.124 117.9 9.372 105.4C21.87 92.88 42.13 92.88 54.63 105.4L191.1 242.7L297.4 137.4C309.9 124.9 330.1 124.9 342.6 137.4L512 306.7V223.1C512 206.3 526.3 191.1 544 191.1C561.7 191.1 576 206.3 576 223.1V384C576 401.7 561.7 416 544 416H384C366.3 416 352 401.7 352 384C352 366.3 366.3 352 384 352L466.7 352z"/></svg>
								</Box>
								<Box  order={2} className="trusted-box2 vertical-center">
									<div className="inner-text">		
									 	<p className="h4-heading font-regular text-blue ">Fewer Accidents and Hazards</p>
									</div>							
								</Box>
							</Box>
						</div>
					</Grid>
					
				</Grid>
			</Grid>
				
		</div>
{/* Our Company Values Section End */}
{/* Start  Simplified section*/}
<div className="custom-container  Simplified section-padding-top  trusted-by-section">
				<Grid container className="" >
					<Grid  item   md={12} xl={12} xs={12} className="vertical-center  text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >We have <span style={{color:"#45BEA0"}}> Simplified </span>the Tech</h2>
						    <p className="subtitle font-regular text-blue-faint">Dori AI provides a suite of template AI computer vision applications across a broad range of manufacturing needs </p>
						</div>
					</Grid>
				<Grid  container spacing={3}>
					<Grid  item  md={3} xl={3} sm={6} xs={12} className=""  alignItems="center"  >
					    <div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
									<img height="100%" width="100%" className="" src="../Images/Real-Time-Feedback.svg" alt="Real Time Feedback" decoding="async" loading="lazy"></img>
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Real-Time Feedback</h4>
										<p className="subtitle2 font-regular text-blue-faint">24/7 live monitoring and alerts for managers and on-site employees</p>
										
									</div>
								
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								
									<img height="100%" width="100%"className="" src="../Images/AI-Driven-Kaizen.svg" alt="AI Driven Kaizen"></img>
																	
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >AI-Driven Decisions</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Capture all activity and enable instant response to all security or safety concerns</p>
										
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item md={3} xl={3} sm={6} xs={12} className=""  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%" className="" src="../Images/Instant-Video-Replay.svg" alt="Instant Video Replay" decoding="async" loading="lazy"></img>
																
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Instant Video Replay</h4>
										<p className="subtitle2 font-regular text-blue-faint ">For on-the-spot troubleshooting and new or existing employee training</p>
										
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item   md={3} xl={3} sm={6}  xs={12} className=""  alignItems="center">
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%" className="" src="../Images/Intelligent-Visual-Search.svg" alt="Intelligent Visual Search" decoding="async" loading="lazy"></img>
									
								
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Intelligent Visual Search</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Search visual metadata by area, branch, zone, or shift for faster root cause analysis</p>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
				</Grid>
				</Grid>
			</div>
{/* End trusted by section*/}
{/* how it works section start*/}
{/*<div className="custom-container  ">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-60">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >How It Works</h2>
						    <p className="subtitle font-regular text-blue-faint">We have standardized the approach to build robust computer vision applications. With our platform, you can get to production within weeks (not months). Our no-code/low-code approach enables subject matter experts to quickly build and configure one of our CV application templates to service any use case.</p>
						</div>
					</Grid>
                </Grid>
               
				<HowIiWorksTab/>
                
                </div>*/}
{/* how it works section End*/}
{/* Start trusted by section*/}
                    <Trustedbyindustries/>
{/* End trusted by section*/}
{/* start case study section*/}
			{/*<div className="custom-container section-padding-top case-study" id="casestudy">
				<Grid container className="text-center">
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >Case Studies</h2>
						    <p className="subtitle font-regular text-blue-faint">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
						</div>
					</Grid>
				</Grid>
				<Grid container className="case-study-inner" spacing={3}>
					<Grid  item  item md={6} xl={6} sm={12} xs={12} className="vertical-center"  alignItems="center"  >
							
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								<Box  order={1}  className="box1">
								<img height="100%" width="100%"className="desktop" src="/Images/Intelligent-visual-inspection-of-assembly-SOPs.jpg" alt="Intelligent visual inspection of assembly SOPs"></img>
									<img height="100%" width="100%"className="mobile" src="/Images/manager-engineer-touch-screen-control-automation-robot-mobile.png" alt="Intelligent visual inspection of assembly SOPs"></img>
								</Box>
								<Box  order={2}  className="box2">
									<div className="inner-text">		
										<h4 className="subtitle font-black padding-bottom-16 text-blue" >Intelligent visual inspection of assembly SOPs</h4>
										<p className="subtitle2 font-regular text-blue-faint">Capturing the actions of each assembly operation from an engine transmission assembly station and automatically flagged.</p>
										<div >
											<ul >
											<li>
												<p className="subtitle font-black padding-bottom-9 text-blue ">50%</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint ">Reduction in maintenance costs  </p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1M+</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Project completed successfully</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">17</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Years of experience</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1000</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">We work with the trusted clients</p>
											</li>
											</ul>
										</div>
										<div className="border"></div>
										<a href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Contact Us</a>
									</div>
								</Box>
							</Box>
						</div>
						
					</Grid>
					<Grid  item  item md={6} xl={6} sm={12} xs={12} className="vertical-center"  alignItems="center"  >
					<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								<Box  order={1}  className="box1">
									<img height="100%" width="100%"className="desktop" src="/Images/Intelligent-visual-inspection-of-assembly-SOPs.jpg" alt="Intelligent visual inspection of assembly SOPs"></img>
									<img height="100%" width="100%"className="mobile" src="/Images/manager-engineer-touch-screen-control-automation-robot-mobile.png" alt="Intelligent visual inspection of assembly SOPs"></img>
								</Box>
								<Box  order={2}  className="box2">
									<div className="inner-text">		
										<h4 className="subtitle font-black padding-bottom-16 text-blue" >Intelligent visual inspection of assembly SOPs</h4>
										<p className="subtitle2 font-regular text-blue-faint">Capturing the actions of each assembly operation from an engine transmission assembly station and automatically flagged.</p>
										<div >
											<ul >
											<li>
												<p className="subtitle font-black padding-bottom-9 text-blue ">50%</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint ">Reduction in maintenance costs  </p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1M+</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Project completed successfully</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">17</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">Years of experience</p>
											</li>
											<li >
												<p className="subtitle font-black padding-bottom-9 text-blue">1000</p>
												<p className="list-subtitle font-regular padding-bottom-14 text-blue-faint">We work with the trusted clients</p>
											</li>
											</ul>
										</div>
										<div className="border"></div>
										
										<a href="#popup1" className="subtitle2 font-bold case-study-anchor link-animation">Contact Us</a>
										
									{/*</div>
								</Box>
							</Box>
						</div>
					</Grid>
					
				</Grid>
				<Grid  item  item md={12} xl={12} xs={12} className="text-center case-study-button" alignItems="center">
					<Button variant="contained" className="footer-btn "><span>Explore more Case Studies</span></Button>
					</Grid>
									</div>*/}
{/* end case study section*/}
{/* Our Trusted & Technology Partnerships Section Start */}
{/*<Grid container className="custom-container technology-partnerships section-container">
				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
					<h2 className="h2-heading padding-bottom-16 text-c font-black text-blue text-center">Our <span class="text-green"> Trusted & Technology </span>Partnerships</h2>
					<p className="subtitle text-blue center-subheading text-center padding-bottom-36 text-blue-faint font-regular" alignItems="center">Dori Ai is helping global brands to significantly increase human productivity & improve process compliances.</p>
				</Grid>
				<Grid container className="grid-outer">
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/AWS.svg" alt="AWS" width="54px" height="32px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Microsoft Azure.svg" alt=" Azure" width="61px" height="33px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Google-cloud-platform-v2.svg" alt="Google-cloud-platform" width="37px" height="32px"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" width="104px" height="20px" item md={4} xl={4} xs={12}>
						<img src="/Images/NVIDIA.svg" alt="NVIDIA" ></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" width="54px" height="36px"  item md={4} xl={4} xs={12}>
						<img src="/Images/intel.svg" alt="intel"></img>
					</Grid>
					<Grid container variant="outlined" className="client-logo" item md={4} xl={4} xs={12}>
						<img src="/Images/Plug-and-Play.svg" alt="Plug-and-Play" width="96px" height="18px"></img>
					</Grid>
			    </Grid>
	</Grid>*/}
			{/* Our Trusted & Technology Partnerships Section End */}
			{/* Start  resources section*/}
{/*<div className="custom-container  Simplified section-padding-bottom trusted-by-section">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >Resources</h2>
						    <p className="subtitle font-regular text-blue-faint">Read more about how we are enabling our partners and customers with a seamless platform to build and deploy computer vision applications with ease. </p>
						</div>
					</Grid>
				<Grid  container spacing={5}>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
					    <div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
									<img height="100%" width="100%"className="" src="/Images/blog3.jpg" alt="Real Time Feedback"></img>
									<div className="inner-text">		
									   <p className="subtitle2 font-black margin-bottom-10 text-blue">Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>
									   <p className="subtitle2 font-regular text-blue-faint">5 min. Read | 12 July 2021</p>
									   <div className="border-inner"></div>
										<Link to="#" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								
							</Box>
						</div>
					</Grid>
			   		 <Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								
									<img height="100%" width="100%"className="" src="/Images/blog1.jpg" alt="AI Driven Kaizen"></img>
																	
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	
										<p className="subtitle2 font-black  text-blue margin-bottom-10 ">Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>
										<p className="subtitle2 font-regular text-blue-faint ">5 min. Read | 12 July 2021</p>
										<div className="border-inner"></div>
										<Link to="#" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={4} xl={4} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%"className="" src="/Images/blog1.jpg" alt="Instant Video Replay"></img>
																
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
											<p className="subtitle2 font-black  text-blue margin-bottom-10">Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>
											<p className="subtitle2 font-regular text-blue-faint ">5 min. Read | 12 July 2021</p>
										   <div className="border-inner"></div>
										   <Link to="#" className="subtitle2 font-bold case-study-anchor link-animation">Learn More</Link>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					
				</Grid>
				</Grid>
</div>*/}
{/* End R section*/}
			{/* We are Nerd Section Start */}
	        <AIPartnerSection/>
{/* We are Nerd Section end */}
<div id="popup1" className="overlay  contact-us-section section-padding-bottom">
				                            <Grid container className="popup" >
			        
												<Grid  item  item md={5} xl={5} xs={12} className="contact-inner"  alignItems="center"  >
												    <a class="close" href="#casestudy">&times;</a>
													<h2 className="h2-heading text-white font-black padding-bottom-20">Contact Us</h2>
													{/*<p className="subtitle font-regular text-white padding-bottom-36">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>*/}
												    <HubspotForm
															region= "na1"
															portalId="6819704"
															formId ="bfac7d9b-11f0-407d-bf0b-f863128a3ceb"
			 
               										 />

												</Grid>
											</Grid>
										</div>
										{/*End form*/}

    </>
  );
}