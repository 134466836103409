import React from "react";
export default function PrivacyPolicy(props) {
    const privacycentersubheading = {maxWidth:"1010px", };
    const list = {marginLeft:"40px", };
	return (
        
		
		<div className="custom-container padding-top-96 padding-bottom-96 ">
		<h1  className="h1-heading text-blue font-black padding-bottom-16">Dori Incorporated <span className="text-green">Privacy Policy</span></h1>
		<p  style={privacycentersubheading} className="subtitle font-regular padding-bottom-30 text-blue-faint">This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.dori.ai or www.dorai.com (both referred to as the “Site”). </p>
        <div id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">PERSONAL INFORMATION WE COLLECT  </h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”  </p>
            <p  className="subtitle font-bold padding-bottom-30 text-blue-faint">We collect Device Information using the following technologies: </p>
            <ul style={list} className="padding-bottom-30 ">
                    <li className="subtitle font-regular margin-bottom-10 text-blue-faint">“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. </li>
                    <li className="subtitle font-regular  margin-bottom-10 text-blue-faint">“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. </li>
                    <li className="subtitle font-regular  text-blue-faint">“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site. </li>
            </ul>
            <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, debit card numbers, or bank account numbers), email address, and phone number. We refer to this information as “Order Information.”  </p>
            <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>
        </div>
        <div id="info " id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">SHARING YOUR PERSONAL INFORMATION  </h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.  </p>
            <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">Screen our orders for potential risk or fraud; and When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services. </p>
            <p  className="subtitle font-regular padding-bottom-30 text-blue-faint"> We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>
        </div>
        <div id="info" id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">HOW DO WE USE YOUR PERSONAL INFORMATION? </h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint"> We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:Communicate with you; </p>
            <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights. </p>
        </div>
        <div id="info" id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">BEHAVIOURAL ADVERTISING</h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work. </p>
            <p  className="subtitle font-bold padding-bottom-30 text-blue-faint">You can opt out of targeted advertising by:  </p>
            <ul style={list} className="padding-bottom-30 ">
                    <li className="subtitle font-regular margin-bottom-10 text-blue-faint">FACEBOOK - https://www.facebook.com/settings/?tab=ads </li>
                    <li className="subtitle font-regular  margin-bottom-10 text-blue-faint">GOOGLE - https://www.google.com/settings/ads/anonymous </li>
            </ul>
            <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/.  </p>
        </div>
        <div id="info" id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">DO NOT TRACK </h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser. </p>
        </div>
        <div id="info" id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">YOUR RIGHTS  </h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. </p>
            <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States. </p>
        </div>
        <div id="info" id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">DATA RETENTION  </h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information. </p>
        </div>
        <div id="info" id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">MINORS  </h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">The Site and any Dori Incorporated software is not intended for individuals under the age of 18. </p>
        </div>
        <div id="info" id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">CHANGES  </h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. </p>
        </div>
        <div id="info" id="info" style={privacycentersubheading}>
			<h3 className="heading-h5 font-regular padding-bottom-16 text-blue">CONTACT US</h3>
		    <p  className="subtitle font-regular padding-bottom-30 text-blue-faint">For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailtto:contact@dori.ai." className="text-green">contact@dori.ai.</a></p>
        </div>
	</div >
		
		);
}