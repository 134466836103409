import { Container, Grid, TextareaAutosize } from "@material-ui/core";

import React, { useRef, useState,useEffect } from 'react';
import "./Platform.css";
import "./index.js";
import {slideUp, slideDown, slideToggle} from './index.js';
import Button from '@mui/material/Button';
import Box from '@material-ui/core/Box';
import Trustedbyindustries from '../components/Trustedbyindustries';
import AIPartnerSection from '../components/AIPartnerSection';



export default function Toggle(props) {

	return (
		
		<section>
    {/*Start banner section */}
			<div className="CommonBanner Platform">
				<div className="CommonBannerImg">
					<img src="/Images/Platform_banner.jpg" alt="Platform page banner" width="100%" height="100%" decoding="async" loading="lazy"></img>
				</div>
				<div className="custom-container CommonBannerContent">
					<h1 className="h1-heading padding-bottom-16 font-black">Full-Stack AI Computer <br></br> <span className="text-green"> Vision Platform </span></h1>
					<p className="subtitle padding-bottom-30 font-regular">End-to-end platform to rapidly build and deploy computer vision applications</p>
					<Button variant="contained" className="rounded-white-btn" href="/contact"><span>Request Demo</span></Button>
				</div>
			</div>
			
      {/*end banner section */}
      <div className="platform section-padding-top  section-padding-bottom margin-top-3 " >
        <Grid className="custom-container bg-pattern" >				
        {/*
          <div id="plateformBox" className="padding-bottom-36">		
    			  <ul >			  
            <li>
    				  <img src="/Images/Centralized-Data-Management.svg" alt="Visual-Data-Engines" className="padding-bottom-16" decoding="async" loading="lazy"></img> 
              <h3 className="subtitle padding-bottom-16 font-black text-blue">Centralized Data Management</h3>
    					<p  className="subtitle2 font-regular text-blue-faint">Connect and configure live cameras and import visual data; automatically augment, annotate, and prepare robust datasets for model training.</p>
    			  </li>			 
            <li className="">
              <img src="/Images/AI-Model-Building-Pipeline.svg" alt="Visual-Data-Connectors" className="padding-bottom-16"></img>
              <h3 className="subtitle padding-bottom-16 font-black text-blue">AI Model Development Pipeline</h3>
              <p  className="subtitle2 font-regular text-blue-faint">Train and analyze CV models without  data science experience; intuitive dashboards enable subject matter experts to build highly accurate models.</p>
            </li>
              
            <li className="">
              <img src="/Images/Scalable-Deployment-Manager.svg" alt="Annotation-Engine" className="padding-bottom-16"></img>
              <h3 className="subtitle padding-bottom-16 font-black text-blue">Scalable Deployment Manager</h3>
              <p  className="subtitle2 font-regular text-blue-faint">Configure and manage edge devices and cloud deployments from centralized dashboards; securely monitor all data and endpoint activity.</p>
            </li>
            <li className=""> 
              <img src="/Images/Enterprise-Application-Integration.svg" alt="Augmentation-Engine" className="padding-bottom-16"></img>
              <h3 className="subtitle padding-bottom-16 font-black text-blue">Enterprise Application Integration</h3>
              <p  className="subtitle2 font-regular text-blue-faint">Leverage prebuilt application templates and set up instant alert notifications; visualize analytics or synchronize data to your systems via APIs.</p>				  
            </li>				  
    			  </ul>
          </div>
        */}
          <div className="plateformBoxFirst padding-top-96 padding-bottom-96" style={{ backgroundImage: `url("../Images/blue-dotted-bg.jpg")` }}>
            <Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
              <h2 className="h2-heading custom-container padding-bottom-36 text-center font-black text-white">One Platform <span class="text-green">Many Applications</span></h2>           
            </Grid>

            <div className="text-center padding-bottom-60 in ">
          		<p className="center-subheading  text-center subtitle font-regular text-white padding-bottom-36">Dori AI provides a suite of tools and technologies that enable enterprises with a full-stack solution to address all their AI computer vision needs.</p>
          		<img src="/Images/dori_full_stack.svg" alt="One-Platform-Endless-Possibilities" className="padding-bottom-16" decoding="async" loading="lazy"></img>
            </div>
            <Grid container className="how-we-are Data-Driven-section">
      				<Grid container direction="row" alignItems="center" className="vertical-center" item md={12} xl={12} xs={12}>
      					<h2 className="h2-heading padding-bottom-16 text-c font-black text-white">Data-Driven <span class="text-green">AI Development </span></h2>
                <p className="center-subheading  text-center subtitle font-regular text-white ">Extract the business value out of your visual data and turn images and videos into real-time operational insights powered by AI and computer vision without the need for a large data science or engineering team.</p>
              </Grid>
      				<Grid container className="grid-outer out">
        				<Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="inner"> 
                   <div className="number-box h2-heading margin-bottom-20 font-black text-green"><span><svg className="svg-icon-color-blue " viewBox="0 0 512 512"><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM99.5 144.8C77.15 176.1 64 214.5 64 256C64 362 149.1 448 256 448C297.5 448 335.9 434.9 367.2 412.5L99.5 144.8zM448 256C448 149.1 362 64 256 64C214.5 64 176.1 77.15 144.8 99.5L412.5 367.2C434.9 335.9 448 297.5 448 256V256z"/></svg><svg className="svg-icon-color-blue code-icon" viewBox="0 0 640 512"><path d="M414.8 40.79L286.8 488.8C281.9 505.8 264.2 515.6 247.2 510.8C230.2 505.9 220.4 488.2 225.2 471.2L353.2 23.21C358.1 6.216 375.8-3.624 392.8 1.232C409.8 6.087 419.6 23.8 414.8 40.79H414.8zM518.6 121.4L630.6 233.4C643.1 245.9 643.1 266.1 630.6 278.6L518.6 390.6C506.1 403.1 485.9 403.1 473.4 390.6C460.9 378.1 460.9 357.9 473.4 345.4L562.7 256L473.4 166.6C460.9 154.1 460.9 133.9 473.4 121.4C485.9 108.9 506.1 108.9 518.6 121.4V121.4zM166.6 166.6L77.25 256L166.6 345.4C179.1 357.9 179.1 378.1 166.6 390.6C154.1 403.1 133.9 403.1 121.4 390.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4L121.4 121.4C133.9 108.9 154.1 108.9 166.6 121.4C179.1 133.9 179.1 154.1 166.6 166.6V166.6z"/></svg></span></div>  
                   <h4 className="subtitle padding-bottom-14 text-blue font-bold">No-Code / Low-Code</h4>
                   <p className="text-blue-faint subtitle2 font-regular">Utilize our visual dashboard interfaces to quickly put together robust and custom AI applications without the need for a data-scientist or full-stack engineer.</p>
        				</Grid>
                <Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="inner">
                  <div className="number-box h2-heading margin-bottom-20 font-black text-green"><span><svg className="svg-icon-color-blue" viewBox="0 0 448 512"><path d="M448 80V128C448 172.2 347.7 208 224 208C100.3 208 0 172.2 0 128V80C0 35.82 100.3 0 224 0C347.7 0 448 35.82 448 80zM393.2 214.7C413.1 207.3 433.1 197.8 448 186.1V288C448 332.2 347.7 368 224 368C100.3 368 0 332.2 0 288V186.1C14.93 197.8 34.02 207.3 54.85 214.7C99.66 230.7 159.5 240 224 240C288.5 240 348.3 230.7 393.2 214.7V214.7zM54.85 374.7C99.66 390.7 159.5 400 224 400C288.5 400 348.3 390.7 393.2 374.7C413.1 367.3 433.1 357.8 448 346.1V432C448 476.2 347.7 512 224 512C100.3 512 0 476.2 0 432V346.1C14.93 357.8 34.02 367.3 54.85 374.7z"/></svg></span></div>
                  <h4 className="subtitle padding-bottom-14 text-blue font-bold">Robust Dataset Creation</h4>
                  <p className="text-blue-faint subtitle2 font-regular">Creating datasets for model training is no longer the job of a data scientist. With our platform, we can augment and synthesize the datasets needed even with just a few source images</p>
                </Grid>
        				<Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="inner"> 
          				<div className="number-box h2-heading margin-bottom-20 font-black text-green"><span><svg  className="svg-icon-color-blue" viewBox="0 0 512 512"><path d="M472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4zM.0003 229.5V80C.0003 53.49 21.49 32 48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5L.0003 229.5zM112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112z"/></svg></span></div>
                  <h4 className="subtitle padding-bottom-14 text-blue font-bold">Automated Data Labeling</h4>
                  <p className="text-blue-faint subtitle2 font-regular">Our proprietary data annotation tools enable subject matter experts to automatically label the ground truth data needed for training via pretrained or custom models.</p>
                </Grid>
                <Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="inner">
                  <div className="number-box h2-heading margin-bottom-20 font-black text-green"><span><svg className="svg-icon-color-blue" viewBox="0 0 512 512"><path d="M0 96C0 60.65 28.65 32 64 32H448C483.3 32 512 60.65 512 96V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96zM96 160C113.7 160 128 145.7 128 128C128 110.3 113.7 96 96 96C78.33 96 64 110.3 64 128C64 145.7 78.33 160 96 160zM448 128C448 114.7 437.3 104 424 104H184C170.7 104 160 114.7 160 128C160 141.3 170.7 152 184 152H424C437.3 152 448 141.3 448 128z"/></svg></span></div>
                  <h4 className="subtitle padding-bottom-14 text-blue font-bold">Application Templates</h4>
                  <p className="text-blue-faint subtitle2 font-regular">Leverage one of our pre-built application templates that can be quickly configured for any use case. Customized views and metrics can easily be integrated and displayed.</p>
                </Grid>
                <Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="inner">
                  <div className="number-box h2-heading margin-bottom-20 font-black text-green"><span><svg className="svg-icon-color-blue" viewBox="0 0 640 512"><path d="M554.9 250.5c-17.62-35.37-68.12-35.25-85.87 0c-86.38 172.7-85.04 165.4-85.04 181.5C383.1 476.1 441.3 512 512 512s127.1-35.88 127.1-79.1C639.1 416.4 642 424.7 554.9 250.5zM439.9 416l72.15-143.1l71.98 143.1H439.9zM512 192c13.41 0 25.89-8.471 30.36-21.88c5.594-16.76-3.469-34.89-20.23-40.48l-122.1-40.1c.3125-2.877 .8712-5.687 .8712-8.648c0-44.18-35.81-80-79.1-80c-29.29 0-54.65 15.92-68.58 39.41l-113.3-37.76C121.3-3.963 103.2 5.162 97.64 21.9C92.05 38.66 101.1 56.78 117.9 62.38l126.3 42.11c7.061 21.84 22.95 39.65 43.78 48.77v294.7H144c-26.51 0-47.1 21.49-47.1 47.1C96 504.8 103.2 512 112 512h223.1c8.836 0 15.1-7.164 15.1-15.1V153.3c5.043-2.207 9.756-4.965 14.19-8.115l135.7 45.23C505.2 191.5 508.7 192 512 192zM256 304c0-15.62 1.1-7.252-85.12-181.5c-17.62-35.37-68.08-35.25-85.83 0c-86.38 172.7-85.04 165.4-85.04 181.5c0 44.12 57.25 79.1 127.1 79.1S256 348.1 256 304zM128 144l72.04 143.1H55.92L128 144z"/></svg></span></div>
                  <h4 className="subtitle padding-bottom-14 text-blue font-bold">Scalable Deployments</h4>
                  <p className="text-blue-faint subtitle2 font-regular">Simultaneously deploy models and applications across all edge, cloud, or hybrid endpoints. Centrally monitor each deployment to enable rapid human-in-the-loop analysis and retraining.</p>
                </Grid>
                <Grid  variant="outlined" item md={4} xl={4} sm={6}  xs={12} className="inner">
                  <div className="number-box h2-heading margin-bottom-20 font-black text-green"><span><svg className="svg-icon-color-blue" viewBox="0 0 512 512"><path d="M232.5 5.171C247.4-1.718 264.6-1.718 279.5 5.171L498.1 106.2C506.6 110.1 512 118.6 512 127.1C512 137.3 506.6 145.8 498.1 149.8L279.5 250.8C264.6 257.7 247.4 257.7 232.5 250.8L13.93 149.8C5.438 145.8 0 137.3 0 127.1C0 118.6 5.437 110.1 13.93 106.2L232.5 5.171zM498.1 234.2C506.6 238.1 512 246.6 512 255.1C512 265.3 506.6 273.8 498.1 277.8L279.5 378.8C264.6 385.7 247.4 385.7 232.5 378.8L13.93 277.8C5.438 273.8 0 265.3 0 255.1C0 246.6 5.437 238.1 13.93 234.2L67.13 209.6L219.1 279.8C242.5 290.7 269.5 290.7 292.9 279.8L444.9 209.6L498.1 234.2zM292.9 407.8L444.9 337.6L498.1 362.2C506.6 366.1 512 374.6 512 383.1C512 393.3 506.6 401.8 498.1 405.8L279.5 506.8C264.6 513.7 247.4 513.7 232.5 506.8L13.93 405.8C5.438 401.8 0 393.3 0 383.1C0 374.6 5.437 366.1 13.93 362.2L67.13 337.6L219.1 407.8C242.5 418.7 269.5 418.7 292.9 407.8V407.8z"/></svg></span></div>
                  <h4 className="subtitle padding-bottom-14 text-blue font-bold">Visual System of Record</h4>
                  <p className="text-blue-faint subtitle2 font-regular">Receive real-time alerts from deployed applications and discover actionable insights from historical visual analytics and dashboard reports.</p>
                </Grid>	
              </Grid>
    		    </Grid>
          </div>		
        </Grid>
      </div>

      {/* Start  Simplified section*/}
      <div className="custom-container  Simplified section-padding-bottom trusted-by-section">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" >We have <span style={{color:"#45BEA0"}}> Simplified </span>the Tech</h2>
						    <p className="subtitle font-regular text-blue-faint">Dori AI provides a suite of template AI computer vision applications across a broad range of manufacturing needs </p>
						</div>
					</Grid>
				<Grid  container spacing={3}>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
					    <div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
									<img height="100%" width="100%"className="" src="../Images/Real-Time-Feedback-platform.svg" alt="Real Time Feedback" decoding="async" loading="lazy"></img>
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Real-Time Feedback</h4>
										<p className="subtitle2 font-regular text-blue-faint">24/7 live monitoring and alerts for zone managers and on-site personnel</p>
										
									</div>
								
							</Box>
						</div>
					</Grid>
			   		 <Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								
									<img height="100%" width="100%"className="" src="../Images/AI-Driven-Kaizen.svg" alt="AI Driven Kaizen" decoding="async" loading="lazy"></img>
																	
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >AI-Driven Decisions</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Capture performance indicators and improve key customer, employee, and business metrics</p>
										
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%"className="" src="../Images/Instant-Video-Replay.svg" alt="Instant Video Replay" decoding="async" loading="lazy"></img>
																
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Instant Video Replay</h4>
										<p className="subtitle2 font-regular text-blue-faint ">For on-the-spot troubleshooting and new or existing employee training</p>
										
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className=""  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%"className="" src="../Images/Intelligent-Visual-Search.svg" alt="Intelligent Visual Search" decoding="async" loading="lazy"></img>
									
								
								<Box  order={2} className="trusted-box2">
									<div className="inner-text">		
									 	<h4 className="h4-heading font-black padding-bottom-16 text-blue" >Intelligent Visual Search</h4>
										<p className="subtitle2 font-regular text-blue-faint ">Search visual metadata by area, branch, zone, or shift for faster root cause analysis</p>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
				</Grid>
				</Grid>
			</div>
      {/* End trusted by section*/}  

      {/*Reach out us Start */}
      <div style={{ backgroundImage: `url("../Images/blue-dotted-bg.jpg")` }}>
        <Grid container direction="row" alignItems="center" className="reach-out-secgtion vertical-center custom-container padding-top-bottom-60 bg-pattern" >
          <h2 className=" center-subheading h3-heading padding-bottom-30 text-c font-bold text-white text-center">Reach out to us to find out more about how we can enable your enterprise actionable visual intelligence and real-time operational insights</h2>
          <Button variant="contained" className="footer-btn" href="/contact"><span>Contact Us</span></Button>
        </Grid>
      </div>
      {/* Reach out us Section end */}

      {/* Start trusted by section*/}
      <Trustedbyindustries/>
      {/* End trusted by section*/}

      {/* We are Nerd Section Start */}
      <AIPartnerSection/>
      {/* We are Nerd Section end */}
    </section>
    );
 }