import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Job.css";

import Link from "@material-ui/core/Link";
import HubspotForm from 'react-hubspot-form';
export default function ProductManagerer(props) {
    
	return (
        <div className="custom-container job-page">
            <Link href="/careers" className="subtitle2 link">Back</Link>
        <div className=" padding-top-bottom-60 ">
            <Grid container spaciing={6}>
					<Grid  item  md={7} sm={12} xs={12} className="vertical-center"  >
						<div className="inner-div ">		
							<h2 className="h2-heading font-black margin-bottom-10 text-blue" >Product Manager</h2>
						 	<p className="subtitle font-regular text-blue">2 - 4 yrs | Bengaluru North, Karnataka, India</p>
                            <div className="job-border"></div>
                            <div className="job-desciption">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Objectives of this Role:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Drive the product and business-planning process across cross-functional teams of the company</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Analyze consumer needs, current market trends, and potential partnerships from an ROI and build vs. buy perspective</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Assess current competitor offerings, seeking opportunities for differentiation</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Analyze product requirements and develop appropriate programs to ensure they’re successful achieved</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Develop, implement, and maintain production timelines across multiple departments</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Appraise new product ideas and strategize appropriate to-market plans</li>
                                  </ul>    
                            </div>   
                            <div className="job-desciption Requirements">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Daily And Monthly Responsibilities:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Drive the execution of all product lifecycle processes for products, including product research, market research, competitive analysis, planning, positioning, roadmap development, requirements development, and product launch</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Translate product strategy into detailed requirements for prototype construction and final product development by engineering teams</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Create product strategy documents that describe business cases, high-level use cases, technical requirements, revenue, and ROI</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Analyze market data to develop sales strategies, and define product objectives for effective marketing communications plans</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Collaborate closely with engineering, production, marketing, and sales teams on the development, QA, and release of products and balance of resources to ensure success for the entire organization</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Develop product positioning and messaging that differentiates Dori Ai and its features across primary market segments</li>
                                    
                                  </ul>    
                            </div>    
                            <div className="job-desciption Requirements">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Skills And Qualifications</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Bachelor’s degree in product design or engineering</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Strong experience in a dynamic product management role</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Proven experience overseeing all elements of the product development lifecycle</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Highly effective cross-functional team management</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Previous experience delivering finely-tuned product marketing strategies</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Exceptional writing and editing skills combined with strong presentation and public speaking skills</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Skills:- Product Management and Product Strategy</li>
                                  </ul>    
                            </div>    
                            <div className="job-desciption Details">
                                  <ul>
                                  <li className="subtitle font-regular padding-bottom-16 text-blue" ><b>Seniority level:</b>
                                  <p>Mid-Senior level</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue">  <b>Employment type:</b>
                                    <p>Full-time</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"> <b>Job function:</b>
                                    <p>Product Management and Marketing</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"><b>Industries:</b>
                                    <p>IT Services and IT Consulting, Software Development, and Internet Publishing</p></li>
                                </ul>    
                            </div>        
                        </div>
					</Grid>
                    {/*<Grid  item  md={5} sm={12} xs={12} className=""  >
						<div className=" ">	
                        <h2 className="h2-heading font-black padding-bottom-36 text-blue" >Application Form</h2>	
						  <ApplicationForm/>
                          
                           <HubspotForm
                             region= 'na1'
                  portalId='6819704'
                  formId='1af92a5c-b450-483a-b450-f7edb713e64b'
                 
                />

        
                        </div>
					</Grid>*/}  
            </Grid>

        </div>
    </div>
    );

}