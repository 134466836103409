import { Container, Grid } from "@material-ui/core";
import React from "react";
import Button from '@mui/material/Button';

export default function AIPartnerSection(props) {
  return( 
    <>
 
{/* We are Nerd Section Start */}
<Grid container direction="row" alignItems="center" className="vertical-center custom-container padding-top-96 padding-bottom-96 bg-pattern top-section-padding" style={{ 
		                           backgroundImage: `url("../Images/blue-dotted-bg.jpg")` }}>
							<h2 className="h1-heading padding-bottom-16 text-c font-black text-white text-center">We’re your <span class="text-green">AI Partner </span></h2>
							<p className="subtitle text-blue center-subheading text-center padding-bottom-30 text-white font-regular " alignItems="center">Reach out to us to schedule a demo or find out how we can help you with your next AI application.</p>
<Button variant="contained" className="rounded-white-btn" href="/contact"><span>Contact Us</span></Button>
							
				</Grid>
{/* We are Nerd Section end */}

    </>
  );
}