import { Container, Grid } from "@material-ui/core";
import React from "react";
import "../pages/Homepage.css";
import ReactDOM from 'react-dom';
import Box from '@material-ui/core/Box';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';


export default function Trustedbyindustries(props) {
	return (
		
		<section>

{/* Start trusted by section*/}
			<div className="custom-container section-padding-top trusted-by-section  section-padding-bottom">
				<Grid container className="" >
					<Grid  item  item md={12} xl={12} xs={12} className="vertical-center text-center"  alignItems="center"  >
						<div className="center-subheading padding-bottom-36">		
							<h2 className="h2-heading font-black padding-bottom-16 text-blue" ><span style={{color:"#45BEA0"}}>Trusted by </span>customers across industries</h2>
						    <p className="subtitle font-regular text-blue-faint">Dori AI provides enterprises with a suite of applications to address the needs of productivity, compliance, quality, and safety </p>
						</div>
					</Grid>
				<Grid  container spacing={3}>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
					    <div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
									<img height="100%" width="100%"className="" src="../Images/Manufacturing.jpg" alt="Manufacturingl" decoding="async" loading="lazy" ></img>
									<div className="inner-text text-center">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Manufacturing</h4>
										<p className="subtitle2 font-regular text-blue-faint padding-bottom-36">Validate SOP compliance <br></br>Detect quality defects <br></br>Monitor workforce productivity</p>
										<Button variant="contained" href="/solutions/manufacturing" className="subtitle2 font-bold MuiButtonBase-root MuiButton-root MuiButton-contained footer-btn"><span>Learn More</span></Button>
									</div>
								
							</Box>
						</div>
					</Grid>
 					<Grid  item  item md={3} xl={3} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%"className="" src="../Images/Logistics.jpg" alt="Logistics" decoding="async" loading="lazy" ></img>
																
								<Box  order={2} className="trusted-box2">
									<div className="inner-text text-center">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Logistics</h4>
										<p className="subtitle2 font-regular text-blue-faint padding-bottom-36">Track equipment/vehicle usage <br></br>Trace pallets and inventory <br></br> Monitor loading activity</p>
										<Button variant="contained" href="/solutions/logistics" className="subtitle2 font-bold MuiButtonBase-root MuiButton-root MuiButton-contained footer-btn"><span>Learn More</span></Button>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
          <Grid  item  item md={3} xl={3} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box   className="box">
								
									<img height="100%" width="100%"className="" src="../Images/Retail.jpg" alt="Site Operations" decoding="async" loading="lazy" ></img>
																	
								<Box  order={2} className="trusted-box2">
									<div className="inner-text text-center">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Site Operations</h4>
										<p className="subtitle2 font-regular text-blue-faint padding-bottom-36">Monitor customer traffic/queues<br></br>Ensure employee performance<br></br>Enhance customer experience</p>
										<Button variant="contained" href="/solutions/siteoperations" className="subtitle2 font-bold MuiButtonBase-root MuiButton-root MuiButton-contained footer-btn"><span>Learn More</span></Button>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
					<Grid  item  item md={3} xl={3} sm={6}xs={12} className="vertical-center"  alignItems="center"  >
						<div style={{ width: '100%' }} className="box-inner">
							<Box  className="box">
								
									<img height="100%" width="100%"className="" src="../Images/Security_Safety.jpg" alt="Intelligent visual inspection of assembly SOPs" decoding="async" loading="lazy" ></img>
									
								
								<Box  order={2} className="trusted-box2">
									<div className="inner-text text-center">		
									 	<h4 className="subtitle font-black padding-bottom-16 text-blue" >Security + Safety</h4>
										<p className="subtitle2 font-regular text-blue-faint padding-bottom-36">Detect PPE/uniform violations <br></br> Flag hazards or potential risks<br></br> Monitor perimeter/restricted zones</p>
									    <Button variant="contained" href="/solutions/securitysafety" className="subtitle2 font-bold MuiButtonBase-root MuiButton-root MuiButton-contained footer-btn"><span>Learn More</span></Button>
									</div>
								</Box>
							</Box>
						</div>
					</Grid>
				</Grid>
				</Grid>
			</div>
{/* End trusted by section*/}
{/* End trusted by section*/}

		
			</section>
		
	);
}
