import React from "react";
import Button from '@mui/material/Button';
export default function Thankyou(props) {
	return (
		<div style={{ backgroundImage: `url("../Images/white-doted-bg.svg")` , backgroundSize: 'cover'}} className="padding-bottom-96 padding-top-96 ">
			<div className="custom-container   text-center" alignItems="center" >
				<h1  className="h1-heading text-blue font-black margin-bottom-16">Thank You<span className="text-green">!</span></h1>
				<div id="info">
					<p  className="subtitle font-regular padding-bottom-30 text-blue-faint">Thank you for submitting your information. You shall  <br></br> receive the details shortly.</p>
					<Button variant="contained" className="footer-btn" href="/"><span>Back to the Page</span></Button>
				</div>
	      </div >
	    </div>
		);
}