import React, { useState, useEffect } from 'react';
import blogPost from '../data/blog.json';
import { NavLink } from 'react-router-dom';
import { Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import './relatedblog.css';
const Relatedblog = (props) => {
   
    const [posts, setPosts] = useState([]);
    
    
    useEffect(() => {
        const posts = blogPost.data;
        setPosts(posts);
       
    }, [posts]);

   

  return(
    <>
       <div className=" section-container  ">


              <div >
                  <div className="recentblog  text-center">
                      <p className='h2-heading font-black text-blue text-center padding-bottom-16'> Related Resources</p>
                      <p className='subtitle text-blue-faint font-regular  padding-bottom-36 text-center '>Read more about how we are enabling our partners and customers with a seamless platform to build and deploy computer vision applications with ease.</p>
                    </div>

                  <div className="relatedPosts ">

                      {posts.slice(0, 3).map(post => {
                        
                          return (
                              <div className='singlerecentpost'>
                              <NavLink key={post.id} to={`/blog/${post.slug}`}>
                                  

                                 
        
       
                                <div className="padding-bottom-20">
                                <img  src={require('../blogPostImages/' + post.blogImage)} alt="Post Image" alt="Dori-logo"></img>
                            </div>     
            
                    <h2 className="subtitle padding-bottom-10 font-black text-blue">{post.blogTitle}</h2>
                    <p className="subtitle2  padding-bottom-16 font-regular min-read ">{post.minRead} min.Read | {post.postedOn}</p>
                    <div className="border-blog"></div>
                    <Link key={post.id} to={`/post/${post.slug}`} className="subtitle2 font-bold case-study-anchor link-animation readmorelink">Read More</Link>
               
       
        
                              </NavLink>
                              
                              </div>
                              
                          
                          );
                    
                      })}
                      
                  </div>
                  
              </div>
          </div>
         
          
          </>
    
   )

 }

export default Relatedblog