import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Job.css";

import Link from "@material-ui/core/Link";
import HubspotForm from 'react-hubspot-form';
export default function SrFullstackDeveloperSystemIntegrationDevOps(props) {
    
	return (
        <div className="custom-container job-page">
            <Link href="/careers" className="subtitle2 link">Back</Link>
        <div className=" padding-top-bottom-60 ">
            <Grid container spaciing={6}>
					<Grid  item  md={7} sm={12} xs={12} className="vertical-center"  >
						<div className="inner-div ">		
							<h2 className="h2-heading font-black margin-bottom-10 text-blue" >Sr. Fullstack Developer - System Integration/DevOps</h2>
						 	<p className="subtitle font-regular text-blue">4+ yrs | Bengaluru North, Karnataka, India</p>
                            <div className="job-border"></div>
                            <div className="job-desciption dec">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Responsibilities:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Participate heavily in brainstorming of system architecture and feature design</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Interface with external customers and key stakeholders to understand and document design requirements</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Work cross functionally with Engineering, Data Science, Product, UX, BD, and Infrastructure teams</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Drive best coding practices across the company (i.e. documentation, code reviews, coding standards, etc)</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Perform security, legal, and license reviews of committed code</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Complete projects with little or no supervision from senior leadership</li>
                                      <li  className="subtitle font-regular text-blue-faint">Integration test, devops and release management</li>
                                  </ul>    
                            </div>   
                            <div className="job-desciption Requirements">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Required Qualifications:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Built and deployed customer-facing services and products at scale</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Developed unit and integration tests</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Worked on products where experimentation and data science are core to development</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience with large-scale distributed systems that have thousands of microservices and manages millions of transactions per day</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Solid instruction-level understanding of Object Oriented design, data structures, and software engineering principles</li>
                                      <li className="subtitle font-regular text-blue-faint ">Must have at least 4+ years experience in front-end and back-end web development with the the following tools: Python, Apache Tomcat, Django, HTML5, CSS, NodeJS, AWS or Azure, Java, MySQL, Flask</li>
                                      
                                  </ul>
                                </div>   
                                  <div className="job-desciption Requirements">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Desired Experience/Skills:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">You have a strong background in natural language processing, statistical modeling, and/or machine learning</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Experience with the following tools: Google Cloud Platform, AWS</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience with open source projects in a startup environment</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">BE, B.Tech or M.Tech in Computer Science, Information Technology, or E and C Skills:- Python, HTML/CSS, MySQL, Amazon Web Services (AWS), Google Cloud Platform (GCP), DevOps, Systems Development Life Cycle (SDLC) and System integration testing</li>
                                </ul>     
                            </div>    
                            <div className="job-desciption Details">
                                  <ul>
                                  <li className="subtitle font-regular padding-bottom-16 text-blue" ><b>Seniority level:</b>
                                  <p> Mid-Senior level</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue">  <b>Employment type:</b>
                                    <p>Full-time</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"> <b>Job function:</b>
                                    <p>Engineering and Information Technology</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"><b>Industries:</b>
                                    <p>IT Services and IT Consulting, Software Development, and Internet Publishing</p></li>
                                </ul>    
                            </div>        
                        </div>
					</Grid>
                    {/*<Grid  item  md={5} sm={12} xs={12} className=""  >
						<div className=" ">	
                        <h2 className="h2-heading font-black padding-bottom-36 text-blue" >Application Form</h2>	
						  <ApplicationForm/>
                          
                           <HubspotForm
                             region= 'na1'
                  portalId='6819704'
                  formId='1af92a5c-b450-483a-b450-f7edb713e64b'
                 
                />

        
                        </div>
					</Grid>*/}
            </Grid>

        </div>
    </div>
    );

}