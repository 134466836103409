import { Container, Grid } from "@material-ui/core";
import React from "react";
import "./Job.css";

import Link from "@material-ui/core/Link";
import HubspotForm from 'react-hubspot-form';
export default function TechnicalContentMarketingSpecialist(props) {
    
	return (
        <div className="custom-container job-page">
            <Link href="/careers" className="subtitle2 link">Back</Link>
        <div className=" padding-top-bottom-60 ">
            <Grid container spaciing={6}>
					<Grid  item  md={7} sm={12} xs={12} className="vertical-center"  >
						<div className="inner-div ">		
							<h2 className="h2-heading font-black margin-bottom-10 text-blue" >Technical Content Marketing Specialist</h2>
						 	<p className="subtitle font-regular text-blue">2 - 4 yrs | Bengaluru North, Karnataka, India</p>
                            <div className="job-border"></div>
                            <div className="job-desciption dec">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Job Description:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">We are looking for a talented Technical Content Marketing Manager to take full responsibility of our inbound marketing strategies including social media, blogs and lead generation. Our ideal candidate is a creative marketing professional, preferably with a technical writing background, that has experience with enterprise software solutions. You should be able to develop engaging content to attract and retain customers. For this position, it’s also essential to be up-to-date with new technologies and marketing trends in new technology areas. Proof of previous work will be asked during the interview process to assess technical writing abilities.</li>
                                      <li  className="subtitle font-regular text-blue-faint">Ultimately, you should be able to expand our company’s digital footprint and brand awareness.</li>
                                     
                                  </ul>    
                            </div>   
                            <div className="job-desciption Requirements">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Responsibilities:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Design content marketing strategies and set short-term goals</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Undertake content marketing initiatives to achieve business targets</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Collaborate with design and writing teams to produce high quality content</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Develop editorial calendar, delegate tasks and ensure deadlines are met</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Deliver engaging content on a regular basis and inspire team members</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Edit, proofread and improve content</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Optimize content considering SEO and Google Analytics</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Analyze web traffic metrics</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Share content through various channels, ensuring strong web presence</li>
                                       <li className="subtitle font-regular text-blue-faint ">Receive customer feedback and generate ideas to increase customer engagement</li>
                                  </ul>    
                            </div>    
                            <div className="job-desciption Requirements">
                                  <h3 className="h3-heading font-black padding-bottom-16 text-blue">Required Qualifications/Skills:</h3>
                                  <ul>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience with SaaS and AI technology a must</li>
                                      <li  className="subtitle font-regular text-blue-faint padding-bottom-20">Proven work experience as a Content Marketing Manager</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Proficiency in MS Office/Google GSuite, or other Content Management Software</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Experience with Adobe product suite or similar software to create marketing material</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Understanding of web publishing requirements</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Prior experience with developer blog sites such as Medium, HackerNews, Quora, Stack Overflow.</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Editorial mindset with an ability to predict audience preferences</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Hands on experience with SEO and web traffic metrics</li>
                                      <li className="subtitle font-regular text-blue-faint padding-bottom-20">Expertise in social media platforms</li>
                                       <li className="subtitle font-regular text-blue-faint ">Project management skills and attention to detail</li>
                                       <li className="subtitle font-regular text-blue-faint ">Excellent communication and writing skills in English is a must</li>
                                       <li className="subtitle font-regular text-blue-faint ">BSc degree in Marketing, Journalism or relevant field</li>
                                       <li className="subtitle font-regular text-blue-faint ">Must be a technology enthusiast that keeps up to date with the latest trends</li>
                                       <li className="subtitle font-regular text-blue-faint ">Skills:- Content Marketing</li>
                                  </ul>    
                            </div>    
                            <div className="job-desciption Details">
                                  <ul>
                                  <li className="subtitle font-regular padding-bottom-16 text-blue" ><b>Seniority level:</b>
                                  <p> Mid-Senior level</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue">  <b>Employment type:</b>
                                    <p> Full-time</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"> <b>Job function:</b>
                                    <p>Marketing and Sales</p></li>
                                    <li className="subtitle font-regular padding-bottom-16 text-blue"><b>Industries:</b>
                                    <p>IT Services and IT Consulting, Software Development, and Internet Publishing</p></li>
                                </ul>    
                            </div>        
                        </div>
					</Grid>
                    {/*<Grid  item  md={5} sm={12} xs={12} className=""  >
						<div className=" ">	
                        <h2 className="h2-heading font-black padding-bottom-36 text-blue" >Application Form</h2>	
						  <ApplicationForm/>
                          
                           <HubspotForm
                             region= 'na1'
                  portalId='6819704'
                  formId='1af92a5c-b450-483a-b450-f7edb713e64b'
                 
                />

        
                        </div>
					</Grid>*/}
            </Grid>

        </div>
    </div>
    );

}