import React from "react";
import Accordion from "./Accordion";
import "./Accordian.css";
import Button from '@mui/material/Button';
let items = [
  <Accordion.Item title="Data Scientist">
    <p className="subtitle font-regular text-blue-faint padding-bottom-30">Dori AI enables enterprises with AI-powered video analytics to significantly increase human productivity and improve process compliance.</p>
    <Button variant="contained" className="footer-btn" href="jobs/apply/DataScientist"><span>Apply Now</span></Button>
  </Accordion.Item>,
  <Accordion.Item title="Python Developer">
   <p className="subtitle font-regular text-blue-faint padding-bottom-30">Dori AI enables enterprises with AI-powered video analytics to significantly increase human productivity and improve process compliance. </p>
    <Button variant="contained" className="footer-btn" href="jobs/apply/pythondeveloper"><span>Apply Now</span></Button>
  </Accordion.Item>,
  <Accordion.Item title="Product Manager">
  <p className="subtitle font-regular text-blue-faint padding-bottom-30">At Dori Ai, we’re proud to offer innovative products that meet the needs and wants of our customers. </p>
   <Button variant="contained" className="footer-btn" href="jobs/apply/productmanager"><span>Apply Now</span></Button>
 </Accordion.Item>,
 <Accordion.Item title="Frontend Developer">
 <p className="subtitle font-regular text-blue-faint padding-bottom-30">All of our team members are expected to learn, learn, and learn! We are working on cutting edge technologies and areas of artificial intelligence that have never been explored before.</p>
  <Button variant="contained" className="footer-btn" href="jobs/apply/frontenddeveloper"><span>Apply Now</span></Button>
</Accordion.Item>,
<Accordion.Item title="UI Developer">
 <p className="subtitle font-regular text-blue-faint padding-bottom-30">All of our team members are expected to learn, learn, and learn! We are working on cutting edge technologies and areas of artificial intelligence that have never been explored before.</p>
  <Button variant="contained" className="footer-btn" href="jobs/apply/uideveloper"><span>Apply Now</span></Button>
</Accordion.Item>,
<Accordion.Item title="Technical Content Marketing Specialist">
 <p className="subtitle font-regular text-blue-faint padding-bottom-30">All of our team members are expected to learn, learn, and learn! We are working on cutting edge technologies and areas of artificial intelligence that have never been explored before.</p>
  <Button variant="contained" className="footer-btn" href="jobs/apply/technicalcontentmarketingspecialist"><span>Apply Now</span></Button>
</Accordion.Item>,
<Accordion.Item title="DevOps Engineer">
 <p className="subtitle font-regular text-blue-faint padding-bottom-30">As a DevOps Engineer and Architect in Dori AI, you will be responsible for streamlining and executing</p>
  <Button variant="contained" className="footer-btn" href="jobs/apply/devOpsengineer"><span>Apply Now</span></Button>
</Accordion.Item>,
<Accordion.Item title="Senior Software Engineer">
 <p className="subtitle font-regular text-blue-faint padding-bottom-30">All of our team members are expected to learn, learn, and learn! We are working on cutting edge technologies and areas of artificial intelligence that have never been explored before.</p>
  <Button variant="contained" className="footer-btn" href="jobs/apply/seniorsoftwareengineer"><span>Apply Now</span></Button>
</Accordion.Item>,
<Accordion.Item title="Sr. Fullstack Developer - System Integration/DevOps">
 <p className="subtitle font-regular text-blue-faint padding-bottom-30">All of our team members are expected to learn, learn, and learn! We are working on cutting edge technologies and areas of artificial intelligence that have never been explored before.</p>
  <Button variant="contained" className="footer-btn" href="jobs/apply/srfullstackdevelopersystemintegrationdevOps"><span>Apply Now</span></Button>
</Accordion.Item>
];

export default function Example() {
  return (
    <div>
      <Accordion items={items} />
    </div>
  );
}
